<template>
  <div class="power">
    <div class="title">power by</div>
    <div class="wrapper">
      <div class="item">
        <img src="@/assets/power/solana.svg" />
      </div>
      <div class="item">
        <img src="@/assets/power/pyth.svg" />
      </div>
      <div class="item">
        <img src="@/assets/power/jup.svg" />
      </div>
      <div class="item">
        <img src="@/assets/power/solscan.svg" />
      </div>
      <div class="item">
        <img src="@/assets/power/squads.svg" />
      </div>
      <div class="item">
        <img src="@/assets/power/wormhole.svg" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
name: 'power',
props: {
    msg: String
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.power {
    max-width: 800px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom: 210px;
  .title {
    color: #DCDCDC;
    text-align: center;
    font-family: 'Poppins',sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    .item:chile(1) {
      img {
        height: 31px;
      }
    }
    .item:chile(2) {
      img {
        height: 39px;
      }
    }
    .item:chile(3) {
      img {
        height: 35px;
      }
    }
    .item:chile(4) {
      img {
        height: 20px;
      }
    }
    .item:chile(5) {
      img {
        height: 31px;
      }
    }
    .item:chile(6) {
      img {
        height: 16px;
      }
    }
    .item {
      width: 33%;
      max-width: 266px;
      margin-top: 60px;
    }
  }
}
@media (max-width: 800px) {
  .power {
    margin-top: 60px;
    .title {
      width: 90%;
      color: #FFF;
      font-family: Inter,sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 40px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .item:chile(1) {
        img {
          height: 31px;
        }
      }
      .item:chile(2) {
        img {
          height: 39px;
        }
      }
      .item:chile(3) {
        img {
          height: 35px;
        }
      }
      .item:chile(4) {
        img {
          height: 20px;
        }
      }
      .item:chile(5) {
        img {
          height: 31px;
        }
      }
      .item:chile(6) {
        img {
          height: 16px;
        }
      }
      .item {
        width: 90%;
        max-width: 415px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        .desc1 {
          color: #C958FF;
          font-family: Inter,sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 12px;
        }
        .desc2 {
          max-width: 80%;
          color: #FFF;
          text-align: left;
          font-family: Inter,sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}
</style>
  