import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
interface Basic {
    sliderList: any
  }

export const profit = defineStore(
  'profit',
  () => {
    const state = reactive<Basic>({
      sliderList: []
    })
    return {
      state
    }
  }
)
