/* eslint-disable */
import axios, {isCancel, AxiosError} from 'axios';
import {RESERVE_ONLINE_DATA} from './value'
import localOnlineReserveList from './onlineReserve.json'
import appStore from '@/store'

export async function getReserveList() {
    try {
      const response = await axios.get(RESERVE_ONLINE_DATA);
      console.log('response.data RESERVE_ONLINE_DATA',response.data);
      return response.data
    } catch (error) {
      console.error(error);
      return localOnlineReserveList
    }
}
export async function getOgscore($publickey) {
    try {
      const url = 'https://papi.nxfi.io/userpoint/'+$publickey
      const response = await axios.get(url);
      const og = JSON.parse(response.data)
      console.log('response.data ogScore',response.data);
      const bullScore = await getBullscore($publickey)
      console.log('response.data bullScore',bullScore);
      if(og) {
        appStore.ogscore.state.ogscore = og.lend_points/100
        appStore.ogscore.state.nxscore = og.lend_points/100
      } else {
        appStore.ogscore.state.nxscore = 0
        appStore.ogscore.state.ogscore = 0
      }
      if(bullScore && bullScore.code === 0) {
        appStore.ogscore.state.bullscore = bullScore.data > 50000 ? 50000 : bullScore.data
        const MaxBullAddScore = appStore.ogscore.state.nxscore/10 > 50000 ? 50000 : appStore.ogscore.state.nxscore/10
        const bullAddScore = appStore.ogscore.state.bullscore > MaxBullAddScore ? MaxBullAddScore : appStore.ogscore.state.bullscore
        appStore.ogscore.state.bullUnlockscore = bullAddScore.toFixed(2)
        appStore.ogscore.state.bullLockscore = (appStore.ogscore.state.bullscore - bullAddScore).toFixed(2)
        appStore.ogscore.state.ogscore = (appStore.ogscore.state.ogscore + bullAddScore).toFixed(2)
      } else {
        appStore.ogscore.state.bullUnlockscore = 0
        appStore.ogscore.state.bullLockscore = 0
        appStore.ogscore.state.bullscore = 0
      }
    } catch (error) {
      console.error(error);
      appStore.ogscore.state.nxscore = 0
      appStore.ogscore.state.ogscore = 0
      appStore.ogscore.state.bullUnlockscore = 0
      appStore.ogscore.state.bullLockscore = 0
      appStore.ogscore.state.bullscore = 0
      //return null
    }
}
export async function getBullscore($publickey) {
    try {
      const url = 'https://www.bullishs.io/api/award/2/nxfi?address='+$publickey
      const response = await axios.get(url);
      return response.data
    } catch (error) {
      console.error(error);
      return null
    }
}
export async function registe($publickey,$referenceCode,$channel) {
  console.log('start registe',$publickey,$referenceCode,$channel)
    try {
      let url='https://papi.nxfi.io/registe/'+$publickey
      if($channel && !$referenceCode) {
        url = url + '?channel=' + $channel
      }
      if($referenceCode && !$channel) {
        url = url + '?referrercode=' + $referenceCode
      }
      if($referenceCode && $channel) {
        url = url + '?referrercode=' + $referenceCode+ '&channel=' + $channel
      }
      const response = await axios.get(url);
      console.log('response.data registe',response.data);
      return JSON.parse(response.data)
    } catch (error) {
      console.error(error);
      return null
    }
}
