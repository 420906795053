import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
interface Basic {
    txList: any
  }

export const pendingtx = defineStore(
  'pendingtx',
  () => {
    const state = reactive<Basic>({
      txList: []
    })
    return {
      state
    }
  }
)
