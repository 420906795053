import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
interface Basic {
    allaccounts: any
  }

export const allaccounts = defineStore(
  'allaccounts',
  () => {
    const state = reactive<Basic>({
      allaccounts: new Map()
    })
    return {
      state
    }
  }
)
