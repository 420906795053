import { __awaiter, __extends, __generator } from "tslib";
/* eslint-disable */
import { struct, u32, u8 } from '@solana/buffer-layout';
import { bool, publicKey, u64 } from '@solana/buffer-layout-utils';
import { PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY, TransactionInstruction } from '@solana/web3.js';
import { Buffer } from 'buffer';
import { BN } from '@coral-xyz/anchor';
import BigNumber from 'bignumber.js';
import { Decimal } from 'decimal.js';
export function wrappedI80F48toBigNumber(_a, scaleDecimal) {
    var value = _a.value;
    if (scaleDecimal === void 0) { scaleDecimal = 0; }
    if (!value)
        return new BigNumber(0);
    var numbers = new Decimal("".concat(value.isNeg() ? '-' : '', "0b").concat(value.abs().toString(2), "p-48")).dividedBy(Math.pow(10, scaleDecimal));
    return new BigNumber(numbers.toString());
}
/**
   * Converts a ui representation of a token amount into its native value as `BN`, given the specified mint decimal amount (default to 6 for USDC).
   */
export function toNumber(amount) {
    var amt;
    if (typeof amount === 'number') {
        amt = amount;
    }
    else if (typeof amount === 'string') {
        amt = Number(amount);
    }
    else {
        amt = amount.toNumber();
    }
    return amt;
}
/**
   * Converts a ui representation of a token amount into its native value as `BN`, given the specified mint decimal amount (default to 6 for USDC).
   */
export function toBigNumber(amount) {
    var amt;
    if (amount instanceof BigNumber) {
        amt = amount;
    }
    else {
        amt = new BigNumber(amount.toString());
    }
    return amt;
}
/**
   * Converts a UI representation of a token amount into its native value as `BN`, given the specified mint decimal amount (default to 6 for USDC).
   */
export function uiToNative(amount, decimals) {
    var amt = toBigNumber(amount);
    return new BN(amt.times(Math.pow(10, decimals)).toFixed(0, BigNumber.ROUND_FLOOR));
}
export function uiToNativeBigNumber(amount, decimals) {
    var amt = toBigNumber(amount);
    return amt.times(Math.pow(10, decimals));
}
/**
   * Converts a native representation of a token amount into its UI value as `number`, given the specified mint decimal amount.
   */
export function nativeToUi(amount, decimals) {
    var amt = toBigNumber(amount);
    return amt.div(Math.pow(10, decimals)).toNumber();
}
// shorten the checksummed version of the input address to have 4 characters at start and end
export function shortenAddress(pubkey, chars) {
    if (chars === void 0) { chars = 4; }
    var pubkeyStr = pubkey.toString();
    return "".concat(pubkeyStr.slice(0, chars), "...").concat(pubkeyStr.slice(-chars));
}
/** Buffer layout for de/serializing a multisig */
export var MultisigLayout = struct([
    u8('m'),
    u8('n'),
    bool('isInitialized'),
    publicKey('signer1'),
    publicKey('signer2'),
    publicKey('signer3'),
    publicKey('signer4'),
    publicKey('signer5'),
    publicKey('signer6'),
    publicKey('signer7'),
    publicKey('signer8'),
    publicKey('signer9'),
    publicKey('signer10'),
    publicKey('signer11')
]);
/** Byte length of a multisig */
export var MULTISIG_SIZE = MultisigLayout.span;
export var SplAccountType;
(function (SplAccountType) {
    SplAccountType[SplAccountType["Uninitialized"] = 0] = "Uninitialized";
    SplAccountType[SplAccountType["Mint"] = 1] = "Mint";
    SplAccountType[SplAccountType["Account"] = 2] = "Account";
})(SplAccountType || (SplAccountType = {}));
export var ACCOUNT_TYPE_SIZE = 1;
/** Base class for errors */
var TokenError = /** @class */ (function (_super) {
    __extends(TokenError, _super);
    function TokenError(message) {
        return _super.call(this, message) || this;
    }
    return TokenError;
}(Error));
export { TokenError };
/** Thrown if an account is not found at the expected address */
var TokenAccountNotFoundError = /** @class */ (function (_super) {
    __extends(TokenAccountNotFoundError, _super);
    function TokenAccountNotFoundError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenAccountNotFoundError'
        });
        return _this;
    }
    return TokenAccountNotFoundError;
}(TokenError));
export { TokenAccountNotFoundError };
/** Thrown if a program state account is not a valid Account */
var TokenInvalidAccountError = /** @class */ (function (_super) {
    __extends(TokenInvalidAccountError, _super);
    function TokenInvalidAccountError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenInvalidAccountError'
        });
        return _this;
    }
    return TokenInvalidAccountError;
}(TokenError));
export { TokenInvalidAccountError };
/** Thrown if a program state account is not owned by the expected token program */
var TokenInvalidAccountOwnerError = /** @class */ (function (_super) {
    __extends(TokenInvalidAccountOwnerError, _super);
    function TokenInvalidAccountOwnerError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenInvalidAccountOwnerError'
        });
        return _this;
    }
    return TokenInvalidAccountOwnerError;
}(TokenError));
export { TokenInvalidAccountOwnerError };
/** Thrown if the byte length of an program state account doesn't match the expected size */
var TokenInvalidAccountSizeError = /** @class */ (function (_super) {
    __extends(TokenInvalidAccountSizeError, _super);
    function TokenInvalidAccountSizeError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenInvalidAccountSizeError'
        });
        return _this;
    }
    return TokenInvalidAccountSizeError;
}(TokenError));
export { TokenInvalidAccountSizeError };
/** Thrown if the mint of a token account doesn't match the expected mint */
var TokenInvalidMintError = /** @class */ (function (_super) {
    __extends(TokenInvalidMintError, _super);
    function TokenInvalidMintError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenInvalidMintError'
        });
        return _this;
    }
    return TokenInvalidMintError;
}(TokenError));
export { TokenInvalidMintError };
/** Thrown if the owner of a token account doesn't match the expected owner */
var TokenInvalidOwnerError = /** @class */ (function (_super) {
    __extends(TokenInvalidOwnerError, _super);
    function TokenInvalidOwnerError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenInvalidOwnerError'
        });
        return _this;
    }
    return TokenInvalidOwnerError;
}(TokenError));
export { TokenInvalidOwnerError };
/** Thrown if the owner of a token account is a PDA (Program Derived Address) */
var TokenOwnerOffCurveError = /** @class */ (function (_super) {
    __extends(TokenOwnerOffCurveError, _super);
    function TokenOwnerOffCurveError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenOwnerOffCurveError'
        });
        return _this;
    }
    return TokenOwnerOffCurveError;
}(TokenError));
export { TokenOwnerOffCurveError };
/** Thrown if an instruction's program is invalid */
var TokenInvalidInstructionProgramError = /** @class */ (function (_super) {
    __extends(TokenInvalidInstructionProgramError, _super);
    function TokenInvalidInstructionProgramError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenInvalidInstructionProgramError'
        });
        return _this;
    }
    return TokenInvalidInstructionProgramError;
}(TokenError));
export { TokenInvalidInstructionProgramError };
/** Thrown if an instruction's keys are invalid */
var TokenInvalidInstructionKeysError = /** @class */ (function (_super) {
    __extends(TokenInvalidInstructionKeysError, _super);
    function TokenInvalidInstructionKeysError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenInvalidInstructionKeysError'
        });
        return _this;
    }
    return TokenInvalidInstructionKeysError;
}(TokenError));
export { TokenInvalidInstructionKeysError };
/** Thrown if an instruction's data is invalid */
var TokenInvalidInstructionDataError = /** @class */ (function (_super) {
    __extends(TokenInvalidInstructionDataError, _super);
    function TokenInvalidInstructionDataError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenInvalidInstructionDataError'
        });
        return _this;
    }
    return TokenInvalidInstructionDataError;
}(TokenError));
export { TokenInvalidInstructionDataError };
/** Thrown if an instruction's type is invalid */
var TokenInvalidInstructionTypeError = /** @class */ (function (_super) {
    __extends(TokenInvalidInstructionTypeError, _super);
    function TokenInvalidInstructionTypeError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenInvalidInstructionTypeError'
        });
        return _this;
    }
    return TokenInvalidInstructionTypeError;
}(TokenError));
export { TokenInvalidInstructionTypeError };
/** Thrown if the program does not support the desired instruction */
var TokenUnsupportedInstructionError = /** @class */ (function (_super) {
    __extends(TokenUnsupportedInstructionError, _super);
    function TokenUnsupportedInstructionError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 'TokenUnsupportedInstructionError'
        });
        return _this;
    }
    return TokenUnsupportedInstructionError;
}(TokenError));
export { TokenUnsupportedInstructionError };
/** Token account state as stored by the program */
export var AccountState;
(function (AccountState) {
    AccountState[AccountState["Uninitialized"] = 0] = "Uninitialized";
    AccountState[AccountState["Initialized"] = 1] = "Initialized";
    AccountState[AccountState["Frozen"] = 2] = "Frozen";
})(AccountState || (AccountState = {}));
/** Buffer layout for de/serializing a token account */
export var AccountLayout = struct([
    publicKey('mint'),
    publicKey('owner'),
    u64('amount'),
    u32('delegateOption'),
    publicKey('delegate'),
    u8('state'),
    u32('isNativeOption'),
    u64('isNative'),
    u64('delegatedAmount'),
    u32('closeAuthorityOption'),
    publicKey('closeAuthority')
]);
/** Byte length of a token account */
export var ACCOUNT_SIZE = AccountLayout.span;
export var NATIVE_MINT = new PublicKey('So11111111111111111111111111111111111111112');
/**
   * Retrieve information about a token account
   *
   * @param connection Connection to use
   * @param address    Token account
   * @param commitment Desired level of commitment for querying the state
   * @param programId  SPL Token program account
   *
   * @return Token account information
   */
export function getAccount(connection, address, commitment, programId) {
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    return __awaiter(this, void 0, Promise, function () {
        var info;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, connection.getAccountInfo(address, commitment)];
                case 1:
                    info = _a.sent();
                    return [2 /*return*/, unpackAccount(address, info, programId)];
            }
        });
    });
}
export var TOKEN_PROGRAM_ID = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA');
/**
   * Retrieve information about multiple token accounts in a single RPC call
   *
   * @param connection Connection to use
   * @param addresses  Token accounts
   * @param commitment Desired level of commitment for querying the state
   * @param programId  SPL Token program account
   *
   * @return Token account information
   */
export function getMultipleAccounts(connection, addresses, commitment, programId) {
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    return __awaiter(this, void 0, Promise, function () {
        var infos;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, connection.getMultipleAccountsInfo(addresses, commitment)];
                case 1:
                    infos = _a.sent();
                    return [2 /*return*/, addresses.map(function (address, i) {
                            // @ts-ignore
                            return unpackAccount(address, infos[i], programId);
                        })];
            }
        });
    });
}
/** Get the minimum lamport balance for a base token account to be rent exempt
   *
   * @param connection Connection to use
   * @param commitment Desired level of commitment for querying the state
   *
   * @return Amount of lamports required
   */
export function getMinimumBalanceForRentExemptAccount(connection, commitment) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getMinimumBalanceForRentExemptAccountWithExtensions(connection, [], commitment)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export var ExtensionType;
(function (ExtensionType) {
    ExtensionType[ExtensionType["Uninitialized"] = 0] = "Uninitialized";
    ExtensionType[ExtensionType["TransferFeeConfig"] = 1] = "TransferFeeConfig";
    ExtensionType[ExtensionType["TransferFeeAmount"] = 2] = "TransferFeeAmount";
    ExtensionType[ExtensionType["MintCloseAuthority"] = 3] = "MintCloseAuthority";
    ExtensionType[ExtensionType["ConfidentialTransferMint"] = 4] = "ConfidentialTransferMint";
    ExtensionType[ExtensionType["ConfidentialTransferAccount"] = 5] = "ConfidentialTransferAccount";
    ExtensionType[ExtensionType["DefaultAccountState"] = 6] = "DefaultAccountState";
    ExtensionType[ExtensionType["ImmutableOwner"] = 7] = "ImmutableOwner";
    ExtensionType[ExtensionType["MemoTransfer"] = 8] = "MemoTransfer";
    ExtensionType[ExtensionType["NonTransferable"] = 9] = "NonTransferable";
    ExtensionType[ExtensionType["InterestBearingMint"] = 10] = "InterestBearingMint";
})(ExtensionType || (ExtensionType = {}));
export function getAccountLen(extensionTypes) {
    return getLen(extensionTypes, ACCOUNT_SIZE);
}
export var TYPE_SIZE = 2;
export var LENGTH_SIZE = 2;
function getLen(extensionTypes, baseSize) {
    if (extensionTypes.length === 0) {
        return baseSize;
    }
    else {
        var accountLength = ACCOUNT_SIZE + ACCOUNT_TYPE_SIZE;
        if (accountLength === MULTISIG_SIZE) {
            return accountLength + TYPE_SIZE;
        }
        else {
            return accountLength;
        }
    }
}
/** Get the minimum lamport balance for a rent-exempt token account with extensions
   *
   * @param connection Connection to use
   * @param extensions
   * @param commitment Desired level of commitment for querying the state
   *
   * @return Amount of lamports required
   */
export function getMinimumBalanceForRentExemptAccountWithExtensions(connection, extensions, commitment) {
    return __awaiter(this, void 0, Promise, function () {
        var accountLen;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    accountLen = getAccountLen(extensions);
                    return [4 /*yield*/, connection.getMinimumBalanceForRentExemption(accountLen, commitment)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
/**
   * Unpack a token account
   *
   * @param address   Token account
   * @param info      Token account data
   * @param programId SPL Token program account
   *
   * @return Unpacked token account
   */
export function unpackAccount(address, info, programId) {
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    if (!info)
        throw new TokenAccountNotFoundError();
    if (!info.owner.equals(programId))
        throw new TokenInvalidAccountOwnerError();
    if (info.data.length < ACCOUNT_SIZE)
        throw new TokenInvalidAccountSizeError();
    var rawAccount = AccountLayout.decode(info.data.slice(0, ACCOUNT_SIZE));
    var tlvData = Buffer.alloc(0);
    if (info.data.length > ACCOUNT_SIZE) {
        if (info.data.length === MULTISIG_SIZE)
            throw new TokenInvalidAccountSizeError();
        if (info.data[ACCOUNT_SIZE] != SplAccountType.Account)
            throw new TokenInvalidAccountError();
        tlvData = info.data.slice(ACCOUNT_SIZE + ACCOUNT_TYPE_SIZE);
    }
    return {
        address: address,
        mint: rawAccount.mint,
        owner: rawAccount.owner,
        amount: rawAccount.amount,
        delegate: rawAccount.delegateOption ? rawAccount.delegate : null,
        delegatedAmount: rawAccount.delegatedAmount,
        isInitialized: rawAccount.state !== AccountState.Uninitialized,
        isFrozen: rawAccount.state === AccountState.Frozen,
        isNative: !!rawAccount.isNativeOption,
        rentExemptReserve: rawAccount.isNativeOption ? rawAccount.isNative : null,
        closeAuthority: rawAccount.closeAuthorityOption ? rawAccount.closeAuthority : null,
        tlvData: tlvData
    };
}
/** Address of the SPL Associated Token Account program */
export var ASSOCIATED_TOKEN_PROGRAM_ID = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');
/**
   * Get the address of the associated token account for a given mint and owner
   *
   * @param mint                     Token mint account
   * @param owner                    Owner of the new account
   * @param allowOwnerOffCurve       Allow the owner account to be a PDA (Program Derived Address)
   * @param programId                SPL Token program account
   * @param associatedTokenProgramId SPL Associated Token program account
   *
   * @return Address of the associated token account
   */
export function getAssociatedTokenAddressSync(mint, owner, allowOwnerOffCurve, programId, associatedTokenProgramId) {
    if (allowOwnerOffCurve === void 0) { allowOwnerOffCurve = false; }
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    if (associatedTokenProgramId === void 0) { associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID; }
    if (!allowOwnerOffCurve && !PublicKey.isOnCurve(owner.toBuffer()))
        throw new TokenOwnerOffCurveError();
    var address = PublicKey.findProgramAddressSync([owner.toBuffer(), programId.toBuffer(), mint.toBuffer()], associatedTokenProgramId)[0];
    return address;
}
/**
   * Construct an AssociatedTokenAccount instruction
   *
   * @param payer                    Payer of the initialization fees
   * @param associatedToken          New associated token account
   * @param owner                    Owner of the new account
   * @param mint                     Token mint account
   * @param programId                SPL Token program account
   * @param associatedTokenProgramId SPL Associated Token program account
   *
   * @return Instruction to add to a transaction
   */
export function createAssociatedTokenAccountInstruction(payer, associatedToken, owner, mint, programId, associatedTokenProgramId) {
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    if (associatedTokenProgramId === void 0) { associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID; }
    var keys = [
        { pubkey: payer, isSigner: true, isWritable: true },
        { pubkey: associatedToken, isSigner: false, isWritable: true },
        { pubkey: owner, isSigner: false, isWritable: false },
        { pubkey: mint, isSigner: false, isWritable: false },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
        { pubkey: programId, isSigner: false, isWritable: false },
        { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false }
    ];
    return new TransactionInstruction({
        keys: keys,
        programId: associatedTokenProgramId,
        data: Buffer.alloc(0)
    });
}
/**
   * Construct a CreateAssociatedTokenAccountIdempotent instruction
   *
   * @param payer                    Payer of the initialization fees
   * @param associatedToken          New associated token account
   * @param owner                    Owner of the new account
   * @param mint                     Token mint account
   * @param programId                SPL Token program account
   * @param associatedTokenProgramId SPL Associated Token program account
   *
   * @return Instruction to add to a transaction
   */
export function createAssociatedTokenAccountIdempotentInstruction(payer, associatedToken, owner, mint, programId, associatedTokenProgramId) {
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    if (associatedTokenProgramId === void 0) { associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID; }
    return buildAssociatedTokenAccountInstruction(payer, associatedToken, owner, mint, Buffer.from([1]), programId, associatedTokenProgramId);
}
/** TODO: docs */
export var syncNativeInstructionData = struct([u8('instruction')]);
/**
   * Construct a SyncNative instruction
   *
   * @param account   Native account to sync lamports from
   * @param programId SPL Token program account
   *
   * @return Instruction to add to a transaction
   */
export function createSyncNativeInstruction(account, programId) {
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    var keys = [{ pubkey: account, isSigner: false, isWritable: true }];
    var data = Buffer.alloc(syncNativeInstructionData.span);
    syncNativeInstructionData.encode({ instruction: TokenInstruction.SyncNative }, data);
    return new TransactionInstruction({ keys: keys, programId: programId, data: data });
}
/** TODO: docs */
export var closeAccountInstructionData = struct([u8('instruction')]);
/**
   * Construct a CloseAccount instruction
   *
   * @param account      Account to close
   * @param destination  Account to receive the remaining balance of the closed account
   * @param authority    Account close authority
   * @param multiSigners Signing accounts if `authority` is a multisig
   * @param programId    SPL Token program account
   *
   * @return Instruction to add to a transaction
   */
export function createCloseAccountInstruction(account, destination, authority, multiSigners, programId) {
    if (multiSigners === void 0) { multiSigners = []; }
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    var keys = addSigners([
        { pubkey: account, isSigner: false, isWritable: true },
        { pubkey: destination, isSigner: false, isWritable: true }
    ], authority, multiSigners);
    var data = Buffer.alloc(closeAccountInstructionData.span);
    closeAccountInstructionData.encode({ instruction: TokenInstruction.CloseAccount }, data);
    return new TransactionInstruction({ keys: keys, programId: programId, data: data });
}
function buildAssociatedTokenAccountInstruction(payer, associatedToken, owner, mint, instructionData, programId, associatedTokenProgramId) {
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    if (associatedTokenProgramId === void 0) { associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID; }
    var keys = [
        { pubkey: payer, isSigner: true, isWritable: true },
        { pubkey: associatedToken, isSigner: false, isWritable: true },
        { pubkey: owner, isSigner: false, isWritable: false },
        { pubkey: mint, isSigner: false, isWritable: false },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
        { pubkey: programId, isSigner: false, isWritable: false }
    ];
    return new TransactionInstruction({
        keys: keys,
        programId: associatedTokenProgramId,
        data: instructionData
    });
}
/** TODO: docs */
export var transferCheckedInstructionData = struct([
    u8('instruction'),
    u64('amount'),
    u8('decimals')
]);
/**
   * Construct a TransferChecked instruction
   *
   * @param source       Source account
   * @param mint         Mint account
   * @param destination  Destination account
   * @param owner        Owner of the source account
   * @param amount       Number of tokens to transfer
   * @param decimals     Number of decimals in transfer amount
   * @param multiSigners Signing accounts if `owner` is a multisig
   * @param programId    SPL Token program account
   *
   * @return Instruction to add to a transaction
   */
export function createTransferCheckedInstruction(source, mint, destination, owner, amount, decimals, multiSigners, programId) {
    if (multiSigners === void 0) { multiSigners = []; }
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    var keys = addSigners([
        { pubkey: source, isSigner: false, isWritable: true },
        { pubkey: mint, isSigner: false, isWritable: false },
        { pubkey: destination, isSigner: false, isWritable: true }
    ], owner, multiSigners);
    var data = Buffer.alloc(transferCheckedInstructionData.span);
    transferCheckedInstructionData.encode({
        instruction: TokenInstruction.TransferChecked,
        amount: BigInt(amount),
        decimals: decimals
    }, data);
    return new TransactionInstruction({ keys: keys, programId: programId, data: data });
}
/** Instructions defined by the program */
export var TokenInstruction;
(function (TokenInstruction) {
    TokenInstruction[TokenInstruction["InitializeAccount"] = 1] = "InitializeAccount";
    TokenInstruction[TokenInstruction["TransferChecked"] = 12] = "TransferChecked";
    TokenInstruction[TokenInstruction["CloseAccount"] = 9] = "CloseAccount";
    TokenInstruction[TokenInstruction["SyncNative"] = 17] = "SyncNative";
})(TokenInstruction || (TokenInstruction = {}));
export var initializeAccountInstructionData = struct([u8('instruction')]);
/**
   * Construct an InitializeAccount instruction
   *
   * @param account   New token account
   * @param mint      Mint account
   * @param owner     Owner of the new account
   * @param programId SPL Token program account
   *
   * @return Instruction to add to a transaction
   */
export function createInitializeAccountInstruction(account, mint, owner, programId) {
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    var keys = [
        { pubkey: account, isSigner: false, isWritable: true },
        { pubkey: mint, isSigner: false, isWritable: false },
        { pubkey: owner, isSigner: false, isWritable: false },
        { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false }
    ];
    var data = Buffer.alloc(initializeAccountInstructionData.span);
    initializeAccountInstructionData.encode({ instruction: TokenInstruction.InitializeAccount }, data);
    return new TransactionInstruction({ keys: keys, programId: programId, data: data });
}
/** @internal */
export function addSigners(keys, ownerOrAuthority, multiSigners) {
    if (multiSigners.length) {
        keys.push({ pubkey: ownerOrAuthority, isSigner: false, isWritable: false });
        for (var _i = 0, multiSigners_1 = multiSigners; _i < multiSigners_1.length; _i++) {
            var signer = multiSigners_1[_i];
            keys.push({
                pubkey: signer.publicKey,
                isSigner: true,
                isWritable: false
            });
        }
    }
    else {
        keys.push({ pubkey: ownerOrAuthority, isSigner: true, isWritable: false });
    }
    return keys;
}
/** Buffer layout for de/serializing a mint */
export var MintLayout = struct([
    u32('mintAuthorityOption'),
    publicKey('mintAuthority'),
    u64('supply'),
    u8('decimals'),
    bool('isInitialized'),
    u32('freezeAuthorityOption'),
    publicKey('freezeAuthority')
]);
/** Byte length of a mint */
export var MINT_SIZE = MintLayout.span;
/**
   * Retrieve information about a mint
   *
   * @param connection Connection to use
   * @param address    Mint account
   * @param commitment Desired level of commitment for querying the state
   * @param programId  SPL Token program account
   *
   * @return Mint information
   */
export function getMint(connection, address, commitment, programId) {
    if (programId === void 0) { programId = TOKEN_PROGRAM_ID; }
    return __awaiter(this, void 0, Promise, function () {
        var info, rawMint;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, connection.getAccountInfo(address, commitment)];
                case 1:
                    info = _a.sent();
                    if (!info)
                        throw new TokenAccountNotFoundError();
                    if (!info.owner.equals(programId))
                        throw new TokenInvalidAccountOwnerError();
                    if (info.data.length != MINT_SIZE)
                        throw new TokenInvalidAccountSizeError();
                    rawMint = MintLayout.decode(info.data);
                    return [2 /*return*/, {
                            address: address,
                            mintAuthority: rawMint.mintAuthorityOption ? rawMint.mintAuthority : null,
                            supply: rawMint.supply,
                            decimals: rawMint.decimals,
                            isInitialized: rawMint.isInitialized,
                            freezeAuthority: rawMint.freezeAuthorityOption ? rawMint.freezeAuthority : null
                        }];
            }
        });
    });
}
export var MEMO_PROGRAM_ID = new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr');
/**
   * Creates and returns an instruction which validates a string of UTF-8
   * encoded characters and verifies that any accounts provided are signers of
   * the transaction.  The program also logs the memo, as well as any verified
   * signer addresses, to the transaction log, so that anyone can easily observe
   * memos and know they were approved by zero or more addresses by inspecting
   * the transaction log from a trusted provider.
   *
   * Public keys passed in via the signerPubkeys will identify Signers which
   * must subsequently sign the Transaction including the returned
   * TransactionInstruction in order for the transaction to be valid.
   *
   * @param memo The UTF-8 encoded memo string to validate
   * @param signerPubkeys An array of public keys which must sign the
   *        Transaction including the returned TransactionInstruction in order
   *        for the transaction to be valid and the memo verification to
   *        succeed.  null is allowed if there are no signers for the memo
   *        verification.
   **/
export function createMemoInstruction(memo, signerPubkeys) {
    var keys = signerPubkeys == null
        ? []
        : signerPubkeys.map(function (key) {
            return { pubkey: key, isSigner: true, isWritable: false };
        });
    return new TransactionInstruction({
        keys: keys,
        programId: MEMO_PROGRAM_ID,
        data: Buffer.from(memo, 'utf8')
    });
}
export function formatP(number) {
    if (!number) {
        return '0';
    }
    if (Math.abs(number) > 1000000000) {
        return (number / 1000000000).toFixed(2).replace(/\.00$/, '') + 'b';
    }
    else if (Math.abs(number) >= 1000000) {
        return (number / 1000000).toFixed(2).replace(/\.00$/, '') + 'm';
    }
    else if (Math.abs(number) >= 1000) {
        return (number / 1000).toFixed(2).replace(/\.00$/, '') + 'k';
    }
    else if (Math.abs(number) >= 0.01) {
        var nf = new Intl.NumberFormat('en-US');
        return nf.format(number); // "1,234,567,890"
    }
    else {
        return '< 0.01';
    }
}
export function formatPU(number) {
    if (Math.abs(number) > 1000000000) {
        return (number / 1000000000).toFixed(2).replace(/\.00$/, '') + 'b';
    }
    else if (Math.abs(number) >= 1000000) {
        return (number / 1000000).toFixed(2).replace(/\.00$/, '') + 'm';
    }
    else if (Math.abs(number) >= 1000) {
        return (number / 1000).toFixed(2).replace(/\.00$/, '') + 'k';
    }
    else if (Math.abs(number) >= 0.1) {
        return Number(number).toFixed(2);
    }
    else if (Math.abs(number) > 0) {
        return '< 0.1';
    }
    else {
        return '0';
    }
}
export function format(number, price) {
    if (Math.abs(number) > 1000000000) {
        return (number / 1000000000).toFixed(2).replace(/\.00$/, '') + 'b';
    }
    else if (Math.abs(number) >= 1000000) {
        return (number / 1000000).toFixed(2).replace(/\.00$/, '') + 'm';
    }
    else if (Math.abs(number) >= 1000) {
        return (number / 1000).toFixed(2).replace(/\.00$/, '') + 'k';
    }
    else if (Math.abs(number) >= 0.01) {
        var nf = new Intl.NumberFormat('en-US');
        return nf.format(number); // "1,234,567,890"
    }
    else {
        if (!price) {
            var nf = new Intl.NumberFormat('en-US');
            return nf.format(number); // "1,234,567,890"
        }
        else {
            if (Math.abs(number) === 0) {
                var nf = new Intl.NumberFormat('en-US');
                return nf.format(number); // "1,234,567,890"
            }
            else {
                if (Math.abs(price) > 100000) {
                    return Number(Number(number).toFixed(7));
                }
                else if (Math.abs(price) >= 10000) {
                    return Number(Number(number).toFixed(6));
                }
                else if (Math.abs(price) >= 1000) {
                    return Number(Number(number).toFixed(5));
                }
                else if (Math.abs(price) >= 100) {
                    return Number(Number(number).toFixed(4));
                }
                else if (Math.abs(price) >= 10) {
                    return Number(Number(number).toFixed(3));
                }
                else if (Math.abs(price) >= 1) {
                    return Number(Number(number).toFixed(2));
                }
                else {
                    return '<0.01';
                }
            }
        }
    }
}
export function formatK(number) {
    var nf = new Intl.NumberFormat('en-US');
    return nf.format(number); // "1,234,567,890"
}
