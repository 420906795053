<template>
  <div class="lend">
    <div class="title">LENDING</div>
    <div class="desc"> with personal risk management tools set</div>
    <div class="wrapper">
      <div class="item">
        <img src="@/assets/img/lend11.svg" />
        <div class="desc1">Auto repay by LTV</div>
        <div class="desc2">Combat the risk of price fluctuations of Borrowed assets</div>
      </div>
      <div class="item">
        <img src="@/assets/img/lend13.svg" />
        <div class="desc1">User-defined risk appetite</div>
        <div class="desc2">Personalized lending parameters based on the preferences and needs</div>
      </div>
      <div class="item">
        <img src="@/assets/img/lend12.svg" />
        <div class="desc1">Long-term high interest rates trigger repay</div>
        <div class="desc2">Avoid losses caused by the excessive borrow ratio of Borrowed assets</div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
name: 'lend',
props: {
    msg: String
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.lend {
  margin-bottom: 326px;
  .title {
    color: #FFF;
    font-family: 'Poppins',sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    text-transform: uppercase;
  }
  .desc {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins',sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 100px;
    opacity:0.5;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top:100px;
    .item {
      width: 30%;
      max-width: 415px;
      img {
        height: 248px;
        margin-bottom: 65px;
      }
      .desc1 {
        color: #C958FF;
        font-family: 'Poppins',sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 12px;
      }
      .desc2 {
        color: #FFF;
        text-align: center;
        font-family: 'Poppins',sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: lowercase;
      }
    }
  }
}
@media (max-width: 800px) {
  .lend {
    margin-top: 60px;
    margin-bottom: 120px;
    .title {
      width: 90%;
      color: #FFF;
      font-family: Inter,sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 10px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    .desc {
      width: 90%;
      color: #FFF;
      text-align: center;
      font-family: 'Poppins',sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 50px;
      margin-left: auto;
      margin-right: auto;
      opacity:0.5;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top:60px;
      .item {
        width: 90%;
        max-width: 415px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        img {
          height: 170px;
          margin-bottom: 17px;
        }
        .desc1 {
          color: #C958FF;
          font-family: Inter,sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 12px;
        }
        .desc2 {
          max-width: 80%;
          color: #FFF;
          text-align: left;
          font-family: Inter,sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}
</style>
  