<template>
  <div class="loading">
    <img class="logo" src="@/assets/img/logo.png">
  </div>
</template>
<script lang="ts">
/* eslint-disable */
</script>
<style scoped lang="scss">
.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.99);
    display: flex;
    justify-content: center;
    align-items:center;
    z-index: 10;
  .logo {
    opacity: 1;
    width: 100px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items:center;
    animation: change 2s ease infinite;
  }
}

@keyframes change {
  0% {
      opacity: 1;
      transform: translate(0,-10%);
  }
  50% {
      opacity: 0.6;
    transform: translate(0,10%);
  }
  100% {
      opacity: 1;
    transform: translate(0,-10%);
  }
}
// @media (max-width: 800px) {
//   .loading {
//     display: none;
//   }
// }
</style>