<template>
  <div class="banner">
      <div class="title">Lending and RWA protocol on Solana</div>
      <div class="desc" @click="open('https://x.com/BuidlerDAO/status/1804023793490497612')">Accelerated by <img @click.stop="open('https://www.springx.net/')" src="@/assets/img/springxw.png"/> & <img @click.stop="open('https://solana.com/')" src="@/assets/img/solana.svg" /><span class="more">>>></span></div>
      <div class="app" @click="goPage('app')">Launch App</div>
      <img class="bg1" src="@/assets/img/bannerbg1.svg" />
      <img class="bg2" src="@/assets/img/bannerbg2.svg" />
  </div>
</template>

<script setup>
/* eslint-disable */
import { useRoute, useRouter, RouteRecordRaw } from 'vue-router'
const router = useRouter()
const toApp=() => {
  window.open("https://alpha.nxfi.io/")
}
const goPage = ($path) => {
  router.push({
    path: `/${$path}`
  })
}
const open = ($url) =>{
  window.open($url)
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.banner {
  // min-height: 837px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1480px;
  min-height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding: 0 246px;
    box-sizing: border-box;
  .bg1 {
    position: absolute;
    right: 0;
    top: 0;
  }
  .bg2 {
    position: absolute;
    right: 0;
    bottom: -31%;
  }
  .title {
    max-width: 889px;
    height: 218px;
    color: #FFF;
    font-family: Inter,sans-serif;
    font-size: 90px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    padding-bottom: 40px;
  }
  .desc {
    cursor: pointer;
    color: #FFF;
    font-family: Inter,sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
    display: flex;
    align-items: center;
    padding-bottom: 64px;
    z-index: 1;
    img {
      margin: 0 10px;
      height: 30px;
      cursor: pointer;
    }
    .more {
      cursor: pointer;
      color: #FFF;
      font-family: Inter,sans-serif;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
  .app {
    cursor: pointer;
    display: flex;
    width: 262px;
    height: 62px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    background: linear-gradient(90deg, #C958FF 0%, #7E57EF 100%);
    color: #FFF;
    font-family: Inter,sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    box-sizing: border-box;
    z-index: 1;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .banner {
    // min-height: 837px;
    min-height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
      align-items: baseline;
      justify-content: center;
      padding: 0 20px;
      box-sizing: border-box;
    .bg1 {
      position: absolute;
      right: 0;
      top: 0;
    }
    .bg2 {
      position: absolute;
      right: 0;
      bottom: -31%;
    }
    .title {
      width: 80%;
      height: auto;
      color: #FFF;
      font-family: Inter,sans-serif;
      font-size: 70px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      padding-bottom: 32px;
      margin-left: 32px;
      margin-right: 32px;
    }
    .desc {
    color: #FFF;
    font-family: Inter,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    img {
      margin: 0 8px;
      height: 22px;
      cursor: pointer;
    }
    .more {
      cursor: pointer;
      color: #FFF;
      font-family: Inter,sans-serif;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
    .app {
      cursor: pointer;
      display: flex;
      width: 240px;
      height: 45px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 12px;
      background: linear-gradient(90deg, #C958FF 0%, #7E57EF 100%);
      color: #FFF;
      font-family: Inter,sans-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      box-sizing: border-box;
      margin-left: 32px;
      margin-right: 32px;
    }
  } 
}
@media (min-width: 800px) and (max-width: 1200px) {
  .banner {
    // min-height: 837px;
    min-height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
      align-items: baseline;
      justify-content: center;
      padding: 0 20px;
      box-sizing: border-box;
    .bg1 {
      position: absolute;
      right: 0;
      top: 0;
    }
    .bg2 {
      position: absolute;
      right: 0;
      bottom: -31%;
    }
    .title {
      width: 80%;
      height: auto;
      color: #FFF;
      font-family: Inter,sans-serif;
      font-size: 60px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      padding-bottom: 32px;
      margin-left: 32px;
      margin-right: 32px;
    }  .desc {
    color: #FFF;
    font-family: Inter,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    img {
      margin: 0 10px;
      height: 18px;
      cursor: pointer;
    }
    .more {
      cursor: pointer;
      color: #FFF;
      font-family: Inter,sans-serif;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
    .app {
      cursor: pointer;
      display: flex;
      width: 240px;
      height: 45px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 12px;
      background: linear-gradient(90deg, #C958FF 0%, #7E57EF 100%);
      color: #FFF;
      font-family: Inter,sans-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      box-sizing: border-box;
      margin-left: 32px;
      margin-right: 32px;
    }
  } 
}
@media (max-width: 800px) {
  .banner {
    // min-height: 837px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
      align-items: baseline;
      justify-content: center;
      padding: 0 20px;
      box-sizing: border-box;
      margin-top: -65px;
    .bg1 {
      position: absolute;
      right: 0;
      top: 0;
    }
    .bg2 {
      position: absolute;
      right: 0;
      bottom: -31%;
    }
    .title {
      width: 80%;
      height: auto;
      color: #FFF;
      font-family: Inter,sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: left;
      padding-bottom: 32px;
      margin-left: 32px;
      margin-right: 32px;
    }
    .desc {
    color: #FFF;
    font-family: Inter,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    img {
      margin: 0 6px;
      height: 14px;
      cursor: pointer;
    }
    .more {
      cursor: pointer;
      color: #FFF;
      font-family: Inter,sans-serif;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
    .app {
      cursor: pointer;
      display: flex;
      width: 80%;
      height: 45px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 12px;
      background: linear-gradient(90deg, #C958FF 0%, #7E57EF 100%);
      color: #FFF;
      font-family: Inter,sans-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      box-sizing: border-box;
      margin-left: 32px;
      margin-right: 32px;
    }
  } 
}
</style>
  