<template>
  <div class="home">
    <banner/>
    <rwa/>
    <lending/>
    <profit/>
    <power/>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable */
import banner from './bannerView.vue'
import lending from './lending.vue'
import rwa from './rwa.vue'
import profit from './profit.vue'
import power from './power.vue'

export default {
name: 'HomeView',
components: {
    banner,
    lending,
    rwa,
    profit,
    power
}
}
</script>