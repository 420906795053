import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
interface Basic {
    ogscore: any,
    nxscore: any,
    bullscore: any,
    bullLockscore: any,
    bullUnlockscore: any,
  }

export const ogscore = defineStore(
  'ogscore',
  () => {
    const state = reactive<Basic>({
      ogscore: 0,
      nxscore: 0,
      bullscore: 0,
      bullLockscore: 0,
      bullUnlockscore: 0
    })
    return {
      state
    }
  }
)
