<template>
  <div class="profit">
    <div class="title">PROFIT ENGINE</div>
    <div class="desc">A one-stop module for investment management</div>
    <div class="wrapper">
      <div class="item">
        <img src="@/assets/img/profit1.png" />
        <div class="desc1">Best yield opportunities</div>
      </div>
      <div class="item">
        <img src="@/assets/img/profit2.png" />
        <div class="desc1">Intuitive earning data</div>
      </div>
      <div class="item">
        <img src="@/assets/img/profit3.png" />
        <div class="desc1">Simple operation</div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
name: 'lend',
props: {
    msg: String
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.profit {
    margin-bottom: 160px;
  .title {
    color: #FFF;
    font-family: 'Poppins',sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    text-transform: uppercase;
  }
  .desc {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins',sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 100px;
    opacity:0.5;
  }
  .wrapper {
    position:relative;
    margin:auto;
    max-width: 970px;
    height: 596px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-image: url('@/assets/img/profitback.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .item:nth-child(1){
      position: absolute;
      top: 0;
      left: 20%;
      img {
        width: 260px;
      }
    }
    .item:nth-child(2){
      position: absolute;
      bottom: 5%;
      left: 40%;
      img {
        width: 220px;
      }
    }
    .item:nth-child(3){
      position: absolute;
      top: 10%;
      left: 60%;
      img {
        width: 190px;
      }
    }
    .item {
      .desc1 {
        color: #FFF;
        font-family: 'Poppins',sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: lowercase;
        opacity:0.5;
      }
    }
  }
}
@media (max-width: 800px) {
  .profit {
    margin-top: 60px;
    .title {
      width: 90%;
      color: #FFF;
      font-family: Inter,sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 10px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    .desc {
      width: 90%;
      color: #FFF;
      text-align: center;
      font-family: 'Poppins',sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 50px;
      margin-left: auto;
      margin-right: auto;
      opacity:0.5;
    }
  .wrapper {
    position:relative;
    margin:auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    background-image: url('@/assets/img/profitback.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .item {
      position: relative !important;
      left:auto !important;
      top:auto !important;
      bottom:auto !important;
      margin-bottom: 30px;
      img {
        width:170px !important;
      }
      .desc1 {
        color: #FFF;
        font-family: 'Poppins',sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: lowercase;
        opacity:0.5;
      }
    }
  }
  }
}
</style>
  