import { __awaiter, __generator } from "tslib";
/* eslint-disable */
import axios from 'axios';
import { RESERVE_ONLINE_DATA } from './value';
import localOnlineReserveList from './onlineReserve.json';
import appStore from '@/store';
export function getReserveList() {
    return __awaiter(this, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get(RESERVE_ONLINE_DATA)];
                case 1:
                    response = _a.sent();
                    console.log('response.data RESERVE_ONLINE_DATA', response.data);
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [2 /*return*/, localOnlineReserveList];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getOgscore($publickey) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response, og, bullScore, MaxBullAddScore, bullAddScore, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    url = 'https://papi.nxfi.io/userpoint/' + $publickey;
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = _a.sent();
                    og = JSON.parse(response.data);
                    console.log('response.data ogScore', response.data);
                    return [4 /*yield*/, getBullscore($publickey)];
                case 2:
                    bullScore = _a.sent();
                    console.log('response.data bullScore', bullScore);
                    if (og) {
                        appStore.ogscore.state.ogscore = og.lend_points / 100;
                        appStore.ogscore.state.nxscore = og.lend_points / 100;
                    }
                    else {
                        appStore.ogscore.state.nxscore = 0;
                        appStore.ogscore.state.ogscore = 0;
                    }
                    if (bullScore && bullScore.code === 0) {
                        appStore.ogscore.state.bullscore = bullScore.data > 50000 ? 50000 : bullScore.data;
                        MaxBullAddScore = appStore.ogscore.state.nxscore / 10 > 50000 ? 50000 : appStore.ogscore.state.nxscore / 10;
                        bullAddScore = appStore.ogscore.state.bullscore > MaxBullAddScore ? MaxBullAddScore : appStore.ogscore.state.bullscore;
                        appStore.ogscore.state.bullUnlockscore = bullAddScore.toFixed(2);
                        appStore.ogscore.state.bullLockscore = (appStore.ogscore.state.bullscore - bullAddScore).toFixed(2);
                        appStore.ogscore.state.ogscore = (appStore.ogscore.state.ogscore + bullAddScore).toFixed(2);
                    }
                    else {
                        appStore.ogscore.state.bullUnlockscore = 0;
                        appStore.ogscore.state.bullLockscore = 0;
                        appStore.ogscore.state.bullscore = 0;
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error(error_2);
                    appStore.ogscore.state.nxscore = 0;
                    appStore.ogscore.state.ogscore = 0;
                    appStore.ogscore.state.bullUnlockscore = 0;
                    appStore.ogscore.state.bullLockscore = 0;
                    appStore.ogscore.state.bullscore = 0;
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function getBullscore($publickey) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    url = 'https://www.bullishs.io/api/award/2/nxfi?address=' + $publickey;
                    return [4 /*yield*/, axios.get(url)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_3 = _a.sent();
                    console.error(error_3);
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function registe($publickey, $referenceCode, $channel) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('start registe', $publickey, $referenceCode, $channel);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    url = 'https://papi.nxfi.io/registe/' + $publickey;
                    if ($channel && !$referenceCode) {
                        url = url + '?channel=' + $channel;
                    }
                    if ($referenceCode && !$channel) {
                        url = url + '?referrercode=' + $referenceCode;
                    }
                    if ($referenceCode && $channel) {
                        url = url + '?referrercode=' + $referenceCode + '&channel=' + $channel;
                    }
                    return [4 /*yield*/, axios.get(url)];
                case 2:
                    response = _a.sent();
                    console.log('response.data registe', response.data);
                    return [2 /*return*/, JSON.parse(response.data)];
                case 3:
                    error_4 = _a.sent();
                    console.error(error_4);
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
