<template>
  <div class="mobile" v-if="route.name !== 'home'">
    <div :class="route.name==='app'?'active item':'item'" @click="goPage('app')">
      <img v-if="route.name==='app'" src="@/assets/img/m_lend.svg"/>
      <img v-else src="@/assets/img/m_lend_d.svg"/>
      <div class="text">Lend</div>
    </div>
    <div :class="route.name==='rwa'?'active item':'item'" @click="goPage('rwa')">
      <img v-if="route.name==='rwa'" src="@/assets/img/m_rwa.svg"/>
      <img v-else src="@/assets/img/m_rwa_d.svg"/>
      <div class="text">RWA</div>
    </div>
    <div class="item">
      <el-tooltip
            class="box-item"
            effect="dark"
            content="Coming soon"
            placement="top"
          >
        <img v-if="route.name==='point'" src="@/assets/img/m_point.svg"/>
        <img v-else src="@/assets/img/m_point_d.svg"/>
      </el-tooltip>
      <div class="text">Point</div>
    </div>
    <!-- <div :class="route.name==='profit'?'active item':'item'" @click="goPage('profit')"> -->
    <div :class="route.name==='profit'?'active item':'item'">
      <el-tooltip
            class="box-item"
            effect="dark"
            content="Coming soon"
            placement="top"
          >
        <img v-if="route.name==='profit'" src="@/assets/img/m_profit.svg"/>
        <img v-else src="@/assets/img/m_profit_d.svg"/>
      </el-tooltip>
      <div class="text">Profit</div>
    </div>
    <div :class="route.name==='activity'?'active item':'item'" @click="goPage('activity')">
      <img v-if="route.name==='activity'" src="@/assets/img/m_event.svg" />
      <img v-else src="@/assets/img/m_event_d.svg" />
      <img class="fire" src="@/assets/img/fire.svg"/>
      <div class="text">Event</div>
    </div>
  </div>
</template>
<script setup>
/* eslint-disable */
import { useRoute, useRouter, RouteRecordRaw } from 'vue-router'
const route = useRoute()
const router = useRouter()
const goPage = ($path) => {
  router.push({
    path: `/${$path}`
  })
}
</script>
<style scoped lang="scss">
@media (min-width:801px) {
  .mobile {
    display: none !important;
  }
}
@media (max-width:800px) {
  .mobile {
    z-index: 10;
    width: 100vw;
    position: fixed;
    bottom: 0px;
    height: 80px;
    background: #141617;
    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.10);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .item {
      width: 20%;
      height: 100%;
      cursor:pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #FFF;
      text-align: center;
      font-family: 'Poppins',sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      justify-content: center;
      position: relative;
      img {
        width: 23px;
      }
      .fire {
        width: 19px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(8px, -33px);
      }
      .text {
        margin-top: 8px;
        color: #898A8B;
        text-align: center;
        font-family: "Poppins",sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }
    }
    .active {
      .text {
        color: #A55FFF !important;
      }
    }
  }
}
</style>