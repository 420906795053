import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
interface Basic {
    reserveList: any,
    onlineReserveList: any
  }

export const reserve = defineStore(
  'reserve',
  () => {
    const state = reactive<Basic>({
      reserveList: [],
      onlineReserveList: []
    })
    const getList = () => {
      return state.reserveList
    }
    return {
      getList,
      state
    }
  }
)
