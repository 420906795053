import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
interface Basic {
    riskLevel: any,
    autoRepayLtv: any,
    change: any,
    reserveChange: any,
    sliderChange: any,
    balanceList: any[]
  }

export const account = defineStore(
  'account',
  () => {
    const state = reactive<Basic>({
      change: 0,
      reserveChange: 0,
      sliderChange: 0,
      riskLevel: 1,
      autoRepayLtv: 0,
      balanceList: []
    })
    return {
      state
    }
  }
)
