/* eslint-disable */
import { getKeypairFromEnvironment } from "@solana-developers/node-helpers"
import * as web3 from "@solana/web3.js"
import Dotenv from "dotenv"
import * as borsh from '@coral-xyz/borsh'
import { Wallet, BorshAccountsCoder,BorshCoder } from "@coral-xyz/anchor"
import { u64 } from '@solana/buffer-layout-utils';
import BigNumber from 'bignumber.js';
import { initWallet, useWallet, useAnchorWallet, AnchorWallet } from 'solana-wallets-vue'
import * as solanaWallet from '@solana/wallet-adapter-react'
import * as token from '@solana/spl-token'
import * as anchor from "@project-serum/anchor";
import { Program } from "@project-serum/anchor";
import  * as pythTool from "../utils/pyth/index"
import * as I80F48Tool from './I80F48';
import { ElMessage } from 'element-plus'
import {getReserveList}  from './online'
import {fireworks} from '@tsparticles/fireworks'
import {confetti} from '@tsparticles/confetti'
import { PriceServiceConnection } from "@pythnetwork/price-service-client";
import { useRoute, useRouter, RouteRecordRaw } from 'vue-router'
import * as bs58 from 'bs58'
import { chunkedGetRawMultipleAccountInfos } from "./chunks";
import {
  InstructionWithEphemeralSigners,
  PythSolanaReceiver,
} from "@pythnetwork/pyth-solana-receiver";

import { Connection,PublicKey } from '@solana/web3.js'
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import type { WalletName } from "@solana/wallet-adapter-base";
import { decodeUTF8 } from "tweetnacl-util";
import {
  integerToDecimal,
  decimalToInteger,
} from '../utils/convertors'
// import { commonTool } from '../utils/common'
import { 
  NATIVE_MINT,
  createAssociatedTokenAccountIdempotentInstruction,
  createSyncNativeInstruction,
  createCloseAccountInstruction,
  getAssociatedTokenAddressSync,
  wrappedI80F48toBigNumber
} from '../utils/common'
import idl  from './nxlend.json'
import {
  programLendingIdStr,
  marketAccKey,
  needUpdate,
  node_rpc,
  HOURS_PER_YEAR,
  look_up_address,
  SOL_WRAP_FACTOR } from './value'
import appStore from '../store'
import { el } from "element-plus/es/locale"

initWallet({autoConnect:true});

const PYTH_PRICE_CONF_INTERVALS = new BigNumber(2.12);
const { sendTransaction,signTransaction,signAllTransactions,wallet,wallets,select: selectWallet } = useWallet()
Dotenv.config()

let programLendingId = new web3.PublicKey(
  programLendingIdStr
)

let marketAcc = new web3.PublicKey(
  marketAccKey
)
const connection = new web3.Connection(node_rpc!, "confirmed");
const lookUpTable = new web3.PublicKey(look_up_address)
// const connection = new web3.Connection(web3.clusterApiUrl("devnet"), "confirmed");
 

export function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

export async function createNxlendAccount(
  needNew: boolean = true,
  noWallet?: Number
){
  const { publicKey } = useWallet()
  if(!publicKey.value && !noWallet) {
    await delay(200)
    return await createNxlendAccount(needNew,1)
  }
  if(!publicKey.value && noWallet) {
    return null
  }
  const userAccSeed = new anchor.BN(50);
  const [userAcc, userAccBump] = web3.PublicKey.findProgramAddressSync(
    [marketAcc.toBuffer(), publicKey.value!.toBuffer(), userAccSeed.toArrayLike(Buffer, "le", 8)],
    programLendingId
  );
  const wallet = useAnchorWallet();
  anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value!, {}));
  const program = new anchor.Program(idl as anchor.Idl, programLendingId);
  let nxlendAccData
  try {
    nxlendAccData = await program.account.nxlendAccount.fetch(userAcc)
    return {
      isExist: true,
      key: userAcc.toBase58(),
      data: nxlendAccData,
      createAccountIx: null
    }
  } catch (error) {
    console.log('nxlendAccount.fetch',error)
    const ix = await program.methods.createUserAccount(userAccSeed
      ).accounts({
        nxlendMarket: marketAcc,
        nxlendAccount: userAcc,
        owner: publicKey.value!,
        feePayer: publicKey.value!,
        systemProgram: web3.SystemProgram.programId
      })
      .signers([])
      .instruction()
      if(!needNew) return null
      return {
        isExist: false,
        key: userAcc.toBase58(),
        data: null,
        createAccountIx: ix
      }
  }
}
async function getNativeBalance (address: any) {
  const pbk = new web3.PublicKey(address)
  try {
    const amount = await connection.getBalance(pbk)
    return integerToDecimal(amount,9)
  } catch (error) {
    console.log('connection.getBalance',error)
    return 0
  }
}
export async function getReserveListOwner (getBalanceFlag: boolean = true,clearBalanceFlag: boolean = false) {
  // const onLineReserveList = await getReserveList()
  let onLineReserveList
  //const onLineReserveList  = localOnlineReserveList
  if(appStore.reserve.state.onlineReserveList.length > 0) {
    onLineReserveList = JSON.parse(JSON.stringify(appStore.reserve.state.onlineReserveList))
  } else {
    onLineReserveList = await getReserveList()
    appStore.reserve.state.onlineReserveList = onLineReserveList
  }
  //let reserveList = JSON.parse(JSON.stringify(reserve_list))
  let sliderList = onLineReserveList.map((e:any)=>{
    e.reserve_mint_key = e.tokenMint
    e.reserve_key = e.reserveAddress
    e.slider = 50
    return e
  })
  appStore.profit.state.sliderList = sliderList
  appStore.account.state.sliderChange = appStore.account.state.sliderChange + 1
  let reserveList = onLineReserveList.map((e:any)=>{
    //e.token_img = require(e.logoURI) ? require(e.logoURI) : require('@/assets/token/sol.webp')
    e.token_img = e.logoURI
    e.token_name = e.tokenSymbol
    e.reserve_mint_key = e.tokenMint
    e.reserve_key = e.reserveAddress
    e.priceFeedID = e.priceFeedID ? e.priceFeedID : null
    e.reserveAta = '',
    e.total_supply = 0,
    e.total_borrow = 0,
    e.supply_apr = 0,
    e.borrow_apr = 0,
    e.supply_apy = 0,
    e.borrow_apy = 0,
    e.ltv = 0,
    e.asset_ntoken_ratio = 0,
    e.debit_ntoken_ratio = 0,
    e.my_supply = 0,
    e.my_borrow = 0,
    e.amount = 0,
    e.capacity = 0,
    e.maxBorrow = 0,
    e.usd_price = 0,
    e.usd_price_low = 0,
    e.usd_price_high = 0,
    e.usd_price_weighted= 0,
    e.usd_price_weighted_low= 0,
    e.usd_price_weighted_high= 0,
    e.decimals=0,
    e.asset_ratio= 0,
    e.asset_liq_ratio= 0,
    e.debit_set_ratio= 0,
    e.debit_high_ratio= 0,
    e.debit_mid_ratio= 0,
    e.debit_low_ratio= 0,
    e.debit_liq_ratio= 0,
    e.type=1,
    e.interestSetting={}
    return e
  })
  const { publicKey } = useWallet()
  // let reserveList = JSON.parse(JSON.stringify(reserve_list))
  let autoRepay
  const wallet = useAnchorWallet();
  anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value!, {}));
  const program = new anchor.Program(idl as anchor.Idl, programLendingId);
  const reserves = reserveList.map((e)=>{
    console.log('e.reserve_key',e.reserve_key)
    return new web3.PublicKey(e.reserve_key)
    //return new web3.PublicKey()
  })
  let nxlendReserveDatas = await getOnchainReserveData(program,reserves)
  // try {
  //   nxlendReserveDatas = await program.account.reserve.fetchMultiple(reserves)
  // } catch (error) {
  //   console.log('fetchMultiple reserve datas',error)
  //   return
  // }
  console.log('nxlendReserveDatas',nxlendReserveDatas)
  //const nxlendReserveDatas = await program.account.reserve.fetchMultiple(reserves)
  let i = 0
  while (i < reserveList.length) {
    const tokenMint = new web3.PublicKey(reserveList[i].reserve_mint_key)
    let nxlendReserveData:any
    nxlendReserveDatas.forEach((e:any) => {
      if(e.tokenMint.equals(tokenMint)) {
        nxlendReserveData = e
      }
    });
    if(!nxlendReserveData) {
      i = i + 1
      continue
    }
    reserveList[i].type = 0
    if(JSON.stringify(nxlendReserveData.setting.reserveType).indexOf('collateral') > -1) {
      reserveList[i].type = 1
    }
    if(JSON.stringify(nxlendReserveData.setting.reserveType).indexOf('noCollaAddup') > -1) {
      reserveList[i].type = 2
    }
    if(reserveList[i].type === 0) {
      ElMessage({
        message: 'error collateral type',
        type: 'warning',
      })
    }
    if(JSON.stringify(nxlendReserveData.setting.oracleType).indexOf('pythEma') > -1) {
      reserveList[i].oracleType = 'pythV1'
    }
    if(JSON.stringify(nxlendReserveData.setting.oracleType).indexOf('pythV2') > -1) {
      reserveList[i].oracleType = 'pythV2'
    }
    reserveList[i].pythOracle = nxlendReserveData.setting.oracleKeys[0].toBase58()
    reserveList[i].capacity = integerToDecimal(nxlendReserveData.setting.capacity,nxlendReserveData.tokenDecimals)
    reserveList[i].maxBorrow = integerToDecimal(nxlendReserveData.setting.maxBorrowable,nxlendReserveData.tokenDecimals)
    reserveList[i].reserveAta = nxlendReserveData.tokenInfo.tokenAccount
    reserveList[i].asset_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.assetValueRatio).toNumber()
    reserveList[i].asset_liq_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.assetValueLiqRatio).toNumber()
    reserveList[i].debit_low_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueRatioLowRisk).toNumber()
    reserveList[i].debit_mid_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueRatioMidRisk).toNumber()
    reserveList[i].debit_high_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueRatioHighRisk).toNumber()
    reserveList[i].debit_liq_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueLiqRatio).toNumber()
    const totalAssetQuantity = getTotalAssetQuantity(wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.reserveAssetNtokenAmount),wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.assetNtokenRatio))
    const totalLiabilityQuantity = getTotalLiabilityQuantity(wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.reserveDebtNtokenAmount),wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.debtNtokenRatio))
    reserveList[i].decimals = nxlendReserveData.tokenDecimals
    // const ltv = new BigNumber(1).div(wrappedI80F48toBigNumber(debitValueRatioRisk)).times(wrappedI80F48toBigNumber(nxlendReserveData.setting.assetValueRatio)).times(100).toFixed(2)
    // reserveList[i].ltv = ltv
    const interestSetting = {
      rateChangeUr1: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.rateChangeUr1).toString(),
      rateChangeUr2: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.rateChangeUr2).toString(),
      irUr1: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.irUr1).toString(),
      irUr2: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.irUr2).toString(),
      maxIr: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.maxIr).toString(),
      treasuryBaseApr: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.treasuryBaseApr).toString(),
      treasuryAdditionRatio: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.treasuryAdditionRatio).toString(),
      marketFeeBase: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.marketFeeBase).toString(),
      marketAdditionRatio: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.marketAdditionRatio).toString()
    }
    reserveList[i].interestSetting = interestSetting
    reserveList[i].asset_ntoken_ratio = nxlendReserveData.creditDebit.assetNtokenRatio
    reserveList[i].debit_ntoken_ratio = nxlendReserveData.creditDebit.debtNtokenRatio
    reserveList[i].total_supply = integerToDecimal(totalAssetQuantity.toFixed(0),nxlendReserveData.tokenDecimals)
    reserveList[i].total_borrow = integerToDecimal(totalLiabilityQuantity.toFixed(0),nxlendReserveData.tokenDecimals)
    const apy = getApyNew(reserveList[i].total_supply,reserveList[i].total_borrow,reserveList[i].interestSetting)
    reserveList[i].supply_apr = Number.isNaN(apy.supplyAPR.toNumber()) ? 0: new BigNumber(apy.supplyAPR).multipliedBy(100).toFixed(2)
    reserveList[i].supply_apy = Number.isNaN(apy.supplyAPY) ? 0:new BigNumber(apy.supplyAPY).multipliedBy(100).toFixed(2)
    reserveList[i].borrow_apr = new BigNumber(apy.borrowAPR).multipliedBy(100).toFixed(2)
    reserveList[i].borrow_apy = new BigNumber(apy.borrowAPY).multipliedBy(100).toFixed(2)
    i = i + 1
  }
  console.log('reserveList',reserveList)
  const pythOracleList = reserveList.map((e)=>{
    return {type: e.oracleType, pythOracle:new web3.PublicKey(e.pythOracle), reserveAddress:e.reserve_key, priceFeed: e.priceFeedID}
  })
  let price_data = await getOnchainPriceData(pythOracleList)
  // try {
  //   price_data = await connection.getMultipleAccountsInfo(pythOracleList)
  // } catch (error) {
  //   console.log('getMultipleAccountsInfo pythOracleList',error)
  // }
  reserveList.forEach(r => {
    price_data.forEach(p => {
      if(p.reserveAddress === r.reserve_key) {
        const priceRes = p.priceData
        r.usd_price = priceRes.priceRealtime.price
        r.usd_price_low = priceRes.priceRealtime.lowestPrice
        r.usd_price_high = priceRes.priceRealtime.highestPrice
        r.usd_price_weighted = priceRes.priceWeighted.price
        r.usd_price_weighted_low = priceRes.priceWeighted.lowestPrice
        r.usd_price_weighted_high = priceRes.priceWeighted.highestPrice
      }
    });
  });
  let risk_level = 1
  let nxlend_acc
  try {
    nxlend_acc = await createNxlendAccount(false)
  } catch (error) {
    console.log('createNxlendAccount',error)
  }
  if(nxlend_acc) {
  //if(nxlend_acc_key && !nxlend_acc_key ) {
    //const nxlend_acc_pbk = new web3.PublicKey(nxlend_acc_key)
    let nxlendAccData = nxlend_acc.data
    // try {
    //   nxlendAccData = await program.account.nxlendAccount.fetch(nxlend_acc_pbk)
    // } catch (error) {
    //   console.log('nxlendAccount.fetch',error)
    // }
    console.log('nxlendAccData',nxlendAccData)
    autoRepay = wrappedI80F48toBigNumber(nxlendAccData.repayLtv).toString()
    const self_risk = JSON.stringify(nxlendAccData.riskPreference)
    if(self_risk.indexOf('low') > -1){
      risk_level = 0
    }
    if(self_risk.indexOf('middle') > -1){
      risk_level = 1
    }
    if(self_risk.indexOf('high') > -1){
      risk_level = 2
    }
    const mybalance = nxlendAccData.userMarketInfo.userReserves
    let balanceActive:any = []
    mybalance.forEach(e => {
      if(e.active) {
        balanceActive.push(e)
      }
    });
    balanceActive.forEach(e => {
      reserveList.forEach(r => {
        if(e.active && e.reservePk.toBase58() === r.reserve_key ) {
          r.my_supply = integerToDecimal(getTotalAssetQuantity(wrappedI80F48toBigNumber(e.assetNtokenAmount),wrappedI80F48toBigNumber(r.asset_ntoken_ratio)).toFixed(0),r.decimals)
          r.my_borrow = integerToDecimal(getTotalLiabilityQuantity(wrappedI80F48toBigNumber(e.debtNtokenAmount),wrappedI80F48toBigNumber(r.debit_ntoken_ratio)).toFixed(0),r.decimals)
          //console.log('supply',getTotalAssetQuantity(wrappedI80F48toBigNumber(e.assetNtokenAmount),wrappedI80F48toBigNumber(r.asset_ntoken_ratio)).toString(10))
          //console.log('borrow',getTotalLiabilityQuantity(wrappedI80F48toBigNumber(e.debtNtokenAmount),wrappedI80F48toBigNumber(r.debit_ntoken_ratio)).toString(10))
        }
      });
    });
    reserveList.forEach(r => {
      switch (risk_level) {
        case 0:
          r.debit_set_ratio = r.debit_low_ratio
          break;
        case 1:
          r.debit_set_ratio = r.debit_mid_ratio
          break;
        case 2:
          r.debit_set_ratio = r.debit_high_ratio
          break;
      }
    });
  } else {
    reserveList.forEach(r => {
      r.my_supply = 0
      r.my_borrow = 0
      r.debit_set_ratio = r.debit_mid_ratio
    });
  }
  reserveList.forEach(e => {
    e.ltv = new BigNumber(1).div(e.debit_set_ratio).times(100).toFixed(2)
  });
  appStore.reserve.state.reserveList = reserveList
  appStore.account.state.riskLevel = risk_level
  appStore.account.state.autoRepayLtv = autoRepay
  appStore.account.state.reserveChange = appStore.account.state.reserveChange + 1
  if(publicKey.value && getBalanceFlag) {
    let j = 0
    while(j < reserveList.length) {
      const r = reserveList[j]
      let amount
      if(r.reserve_mint_key === NATIVE_MINT.toBase58()) {
        amount = await getNativeBalance(publicKey.value)
      } else {
        try {
          const user_token_acc_key = await createTokenAccount(
            connection,
            publicKey.value!,
            new web3.PublicKey(r.reserve_mint_key),
            publicKey.value!,
            true,
            false
          )
          amount = user_token_acc_key? integerToDecimal(user_token_acc_key.amount,r.decimals) : 0
        } catch (error) {
          amount = 0
        }
                
      }
      const balance = {
        mintKey: r.reserve_mint_key,
        amount: amount
      }
      if(appStore.account.state.balanceList.length < reserveList.length) {
        appStore.account.state.balanceList[j]= balance
      } else {
        appStore.account.state.balanceList.forEach(e => {
          if(e.mintKey === balance.mintKey) {
            e.amount = balance.amount
          }
        });
      }
      appStore.account.state.change = appStore.account.state.change + 1
      j= j + 1
    }
  } 
  else if(clearBalanceFlag) {
    let j = 0
    while(j < reserveList.length) {
      const r = reserveList[j]
      const amount = 0
      const balance = {
        mintKey: r.reserve_mint_key,
        amount: amount
      }
      appStore.account.state.balanceList.push(balance)
      appStore.account.state.change = appStore.account.state.change + 1
      j= j + 1
    } 
  }
  appStore.account.state.change = appStore.account.state.change + 1
  appStore.account.state.reserveChange = appStore.account.state.reserveChange + 1
  // return reserveList
  console.log('reserveList',reserveList)
}
async function getOnchainPriceData(priceList:any) {
  console.log('priceList',priceList)
  let priceData
  let v1list:any[] = []
  let v2list:any[] = []
  priceList.forEach(e => {
    if(e.type === 'pythV1') {
      v1list.push(e)
    }
    if(e.type === 'pythV2') {
      v2list.push(e)
    }
  });
  console.log('v1list',v1list)
  console.log('v2list',v2list)
  try {
    const priceAccount = v1list.map(p=>{
      return p.pythOracle
    })
    const res = await connection.getMultipleAccountsInfo(priceAccount)
    let priceDataList:any[] = []
    for(let i = 0; i < v1list.length; i++) {
      const priceData1 = pythTool.parsePriceData(res[i]!.data)
      const priceData = getPrice(priceData1)
      const item = {type:v1list[i].type,priceData:priceData,reserveAddress:v1list[i].reserveAddress,time:priceData.time}
      priceDataList.push(item)
    }
    const priceAccountList = v2list.map(p=>{
      return p.pythOracle
    })
    console.log('priceAccountList',priceAccountList)
    const v2PriceData = await getPriceV2FromAccount(priceAccountList)
    //const v2PriceData = await getPriceV2(priceFeedList)
    console.log('v2PriceData',v2PriceData)
    for(let i = 0; i < v2list.length; i++) {
      const priceData = v2PriceData && v2PriceData[i]
      const item = {type:v2list[i].type,priceData:priceData,reserveAddress:v2list[i].reserveAddress,time:priceData!.time}
      priceDataList.push(item)
    }
    let time = new Date().getTime()
    priceDataList.forEach(p=>{
      const cut = new BigNumber(p.time).times(1000).minus(Number(time)).toString()
      console.log(p.reserveAddress)
      console.log('cut',cut)
    })
    console.log('priceDataList',priceDataList)
    return priceDataList
  } catch (error) {
    await delay(5000)
    console.log('getMultipleAccountsInfo pythOracleList',error)
    priceData = await getOnchainPriceData(priceList)
    return priceData
  }
}
async function getOnchainReserveData(program: any,reserveList:any) {
  let reserveListData
  try {
    reserveListData = await program.account.reserve.fetchMultiple(reserveList)
    return reserveListData
  } catch (error) {
    console.log('getOnchain reserve Data failed',error)
    await delay(500)
    reserveListData = await getOnchainReserveData(program,reserveList)
    return reserveListData
  }
}
async function getPriceV2 ($priceFeedList) {
  const priceServiceConnection = new PriceServiceConnection(
    "https://hermes.pyth.network/",
    { priceFeedRequestConfig: { binary: true } }
  );
  const price_data = await priceServiceConnection.getLatestPriceFeeds($priceFeedList)
  console.log('price_data',price_data)
  const v2PriceData = price_data?.map(e=>{
    const data = e.toJson()  
    const pythPriceRealtime = new BigNumber(data.price.price).shiftedBy(data.price.expo);
    const pythConfidenceRealtime = new BigNumber(data.price.conf).shiftedBy(data.price.expo);
    const pythLowestPriceRealtime = pythPriceRealtime.minus(pythConfidenceRealtime.times(PYTH_PRICE_CONF_INTERVALS));
    const pythHighestPriceRealtime = pythPriceRealtime.plus(pythConfidenceRealtime.times(PYTH_PRICE_CONF_INTERVALS));
  
    const pythPrice = new BigNumber(data.ema_price.price).shiftedBy(data.ema_price.expo);
    const pythConfInterval = new BigNumber(data.ema_price.conf).shiftedBy(data.ema_price.expo);
    const pythLowestPrice = pythPrice.minus(pythConfInterval.times(PYTH_PRICE_CONF_INTERVALS));
    const pythHighestPrice = pythPrice.plus(pythConfInterval.times(PYTH_PRICE_CONF_INTERVALS));
    const time = data.price.publish_time
    console.log(pythPriceRealtime.toString())
    console.log(pythPrice.toString())
    const priceRealtime = {
      price: pythPriceRealtime,
      confidence: pythConfidenceRealtime,
      lowestPrice: pythLowestPriceRealtime,
      highestPrice: pythHighestPriceRealtime,
    }
    const priceWeighted = {
      price: pythPrice,
      confidence: pythConfInterval,
      lowestPrice: pythLowestPrice,
      highestPrice: pythHighestPrice,
    }
    const priceData = {
        time:time,
        priceRealtime: priceRealtime,
        priceWeighted: priceWeighted
      }
    return priceData
  })
  return v2PriceData
}
async function getPriceV2FromAccount ($priceAccountList) {
  const wallet = useAnchorWallet();
  const pythSolanaReceiver = new PythSolanaReceiver({
    connection,
    wallet: wallet.value! as Wallet,
  });
  let priceDataList:any[] = []
  let i = 0
  while(i<$priceAccountList.length) {
    const data = await pythSolanaReceiver.fetchPriceUpdateAccount($priceAccountList[i])
    priceDataList.push(data?.priceMessage)
    i = i + 1
  }
  const v2PriceData = priceDataList?.map(e=>{
    const data = e
    const pythPriceRealtime = new BigNumber(data.price).shiftedBy(data.exponent);
    const pythConfidenceRealtime = new BigNumber(data.conf).shiftedBy(data.exponent);
    const pythLowestPriceRealtime = pythPriceRealtime.minus(pythConfidenceRealtime.times(PYTH_PRICE_CONF_INTERVALS));
    const pythHighestPriceRealtime = pythPriceRealtime.plus(pythConfidenceRealtime.times(PYTH_PRICE_CONF_INTERVALS));
  
    const pythPrice = new BigNumber(data.emaPrice).shiftedBy(data.exponent);
    const pythConfInterval = new BigNumber(data.emaConf).shiftedBy(data.exponent);
    const pythLowestPrice = pythPrice.minus(pythConfInterval.times(PYTH_PRICE_CONF_INTERVALS));
    const pythHighestPrice = pythPrice.plus(pythConfInterval.times(PYTH_PRICE_CONF_INTERVALS));
    const time = data.publishTime
    console.log(pythPriceRealtime.toString())
    console.log(pythPrice.toString())
    const priceRealtime = {
      price: pythPriceRealtime,
      confidence: pythConfidenceRealtime,
      lowestPrice: pythLowestPriceRealtime,
      highestPrice: pythHighestPriceRealtime,
    }
    const priceWeighted = {
      price: pythPrice,
      confidence: pythConfInterval,
      lowestPrice: pythLowestPrice,
      highestPrice: pythHighestPrice,
    }
    const priceData = {
        time:time,
        priceRealtime: priceRealtime,
        priceWeighted: priceWeighted
      }
    return priceData
  })
  return v2PriceData
}
function getPrice(pythPriceData:any) {
  let priceData = pythPriceData.price;
  if (priceData === undefined) {
    priceData = pythPriceData.previousPrice;
  }

  let confidenceData = pythPriceData.confidence;
  if (confidenceData === undefined) {
    confidenceData = pythPriceData.previousConfidence;
  }
  const time = pythPriceData.timestamp
  const pythPriceRealtime = new BigNumber(priceData!);
  const pythConfidenceRealtime = new BigNumber(confidenceData!);
  const pythLowestPriceRealtime = pythPriceRealtime.minus(pythConfidenceRealtime.times(PYTH_PRICE_CONF_INTERVALS));
  const pythHighestPriceRealtime = pythPriceRealtime.plus(pythConfidenceRealtime.times(PYTH_PRICE_CONF_INTERVALS));

  const pythPrice = new BigNumber(pythPriceData.emaPrice.value);
  const pythConfInterval = new BigNumber(pythPriceData.emaConfidence.value);
  const pythLowestPrice = pythPrice.minus(pythConfInterval.times(PYTH_PRICE_CONF_INTERVALS));
  const pythHighestPrice = pythPrice.plus(pythConfInterval.times(PYTH_PRICE_CONF_INTERVALS));
  return {
    time:time,
    priceRealtime: {
      price: pythPriceRealtime,
      confidence: pythConfidenceRealtime,
      lowestPrice: pythLowestPriceRealtime,
      highestPrice: pythHighestPriceRealtime,
    },
    priceWeighted: {
      price: pythPrice,
      confidence: pythConfInterval,
      lowestPrice: pythLowestPrice,
      highestPrice: pythHighestPrice,
    },
  };
}
export function getApy(
  supply: any,
  borrow: any,
  interestSetting: any
){
  const ONE = new BigNumber(1)
  const supplyBN = new BigNumber(supply)
  const borrowBN = new BigNumber(borrow)
  const use = borrowBN.dividedBy(supplyBN)
  const rateChangeUr1 = wrappedI80F48toBigNumber(interestSetting.rateChangeUr1)
  const rateChangeUr2 = wrappedI80F48toBigNumber(interestSetting.rateChangeUr2)
  const irUr1 = wrappedI80F48toBigNumber(interestSetting.irUr1)
  const irUr2 = wrappedI80F48toBigNumber(interestSetting.irUr2)
  const maxIr  = wrappedI80F48toBigNumber(interestSetting.maxIr)
  const treasuryBaseApr = wrappedI80F48toBigNumber(interestSetting.treasuryBaseApr)
  const treasuryAdditionRatio = wrappedI80F48toBigNumber(interestSetting.treasuryAdditionRatio)
  const marketFeeBase = wrappedI80F48toBigNumber(interestSetting.marketFeeBase)
  const marketAdditionRatio = wrappedI80F48toBigNumber(interestSetting.marketAdditionRatio)
  let borrowBaseAPY = new BigNumber(0)
  if(use.lte(rateChangeUr1)) {
    borrowBaseAPY = use.dividedBy(rateChangeUr1).multipliedBy(irUr1)
  }
  if(use.lte(rateChangeUr2) && use.gt(rateChangeUr1)) {
    borrowBaseAPY = (use.minus(rateChangeUr1)).dividedBy(rateChangeUr2.minus(rateChangeUr1)).multipliedBy(irUr2.minus(irUr1)).plus(irUr1)
  }
  if(use.gt(rateChangeUr2)) {
    borrowBaseAPY = (use.minus(rateChangeUr2)).dividedBy(ONE.minus(rateChangeUr2)).multipliedBy(maxIr.minus(irUr2)).plus(irUr2)
  }
  const supplyAPY = (borrowBaseAPY.multipliedBy(use))
  const borrowAPY = (borrowBaseAPY.multipliedBy(treasuryAdditionRatio.plus(marketAdditionRatio).plus(1)).plus(treasuryBaseApr.plus(marketFeeBase)))
  const apy = {
    supplyAPY: supplyAPY,
    borrowAPY: borrowAPY,
  }
  return apy
}
export function getApyNew(
  supply: any,
  borrow: any,
  interestSetting: any
){
  const ONE = new BigNumber(1)
  const supplyBN = new BigNumber(supply)
  const borrowBN = new BigNumber(borrow)
  const use = borrowBN.dividedBy(supplyBN)
  const rateChangeUr1 = new BigNumber(interestSetting.rateChangeUr1)
  const rateChangeUr2 = new BigNumber(interestSetting.rateChangeUr2)
  const irUr1 = new BigNumber(interestSetting.irUr1)
  const irUr2 = new BigNumber(interestSetting.irUr2)
  const maxIr  = new BigNumber(interestSetting.maxIr)
  const treasuryBaseApr = new BigNumber(interestSetting.treasuryBaseApr)
  const treasuryAdditionRatio = new BigNumber(interestSetting.treasuryAdditionRatio)
  const marketFeeBase = new BigNumber(interestSetting.marketFeeBase)
  const marketAdditionRatio = new BigNumber(interestSetting.marketAdditionRatio)
  let borrowBaseAPY = new BigNumber(0)
  if(use.lte(rateChangeUr1)) {
    borrowBaseAPY = use.dividedBy(rateChangeUr1).multipliedBy(irUr1)
  }
  if(use.lte(rateChangeUr2) && use.gt(rateChangeUr1)) {
    borrowBaseAPY = (use.minus(rateChangeUr1)).dividedBy(rateChangeUr2.minus(rateChangeUr1)).multipliedBy(irUr2.minus(irUr1)).plus(irUr1)
  }
  if(use.gt(rateChangeUr2)) {
    borrowBaseAPY = (use.minus(rateChangeUr2)).dividedBy(ONE.minus(rateChangeUr2)).multipliedBy(maxIr.minus(irUr2)).plus(irUr2)
  }
  const supplyAPR = borrowBaseAPY.multipliedBy(use)
  const supplyAPY = aprToApy((borrowBaseAPY.multipliedBy(use)).toNumber())
  const borrowAPR = borrowBaseAPY.multipliedBy(treasuryAdditionRatio.plus(marketAdditionRatio).plus(1)).plus(treasuryBaseApr.plus(marketFeeBase))
  const borrowAPY = aprToApy((borrowBaseAPY.multipliedBy(treasuryAdditionRatio.plus(marketAdditionRatio).plus(1)).plus(treasuryBaseApr.plus(marketFeeBase))).toNumber())
  const apy = {
    supplyAPR: supplyAPR,
    supplyAPY: supplyAPY,
    borrowAPR: borrowAPR,
    borrowAPY: borrowAPY,
  }
  return apy
}
export function getAprNew(
  supply: any,
  borrow: any,
  interestSetting: any
){
  const ONE = new BigNumber(1)
  const supplyBN = new BigNumber(supply)
  const borrowBN = new BigNumber(borrow)
  const use = borrowBN.dividedBy(supplyBN)
  const rateChangeUr1 = new BigNumber(interestSetting.rateChangeUr1)
  const rateChangeUr2 = new BigNumber(interestSetting.rateChangeUr2)
  const irUr1 = new BigNumber(interestSetting.irUr1)
  const irUr2 = new BigNumber(interestSetting.irUr2)
  const maxIr  = new BigNumber(interestSetting.maxIr)
  const treasuryBaseApr = new BigNumber(interestSetting.treasuryBaseApr)
  const treasuryAdditionRatio = new BigNumber(interestSetting.treasuryAdditionRatio)
  const marketFeeBase = new BigNumber(interestSetting.marketFeeBase)
  const marketAdditionRatio = new BigNumber(interestSetting.marketAdditionRatio)
  let borrowBaseAPY = new BigNumber(0)
  if(use.lte(rateChangeUr1)) {
    borrowBaseAPY = use.dividedBy(rateChangeUr1).multipliedBy(irUr1)
  }
  if(use.lte(rateChangeUr2) && use.gt(rateChangeUr1)) {
    borrowBaseAPY = (use.minus(rateChangeUr1)).dividedBy(rateChangeUr2.minus(rateChangeUr1)).multipliedBy(irUr2.minus(irUr1)).plus(irUr1)
  }
  if(use.gt(rateChangeUr2)) {
    borrowBaseAPY = (use.minus(rateChangeUr2)).dividedBy(ONE.minus(rateChangeUr2)).multipliedBy(maxIr.minus(irUr2)).plus(irUr2)
  }
  const supplyAPR = borrowBaseAPY.multipliedBy(use)
  const borrowAPR = borrowBaseAPY.multipliedBy(treasuryAdditionRatio.plus(marketAdditionRatio).plus(1)).plus(treasuryBaseApr.plus(marketFeeBase))
  const apy = {
    supplyAPR: supplyAPR,
    borrowAPR: borrowAPR,
  }
  return apy
}
export async function getAccData(
  nxlend_acc_key:any
){
  const wallet = useAnchorWallet();
  const nxlend_acc_pbk = new web3.PublicKey(nxlend_acc_key)
  anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value!, {}));
  const program = new anchor.Program(idl as anchor.Idl, programLendingId);
  const nxlendAccData = await program.account.nxlendAccount.fetch(nxlend_acc_pbk)
  return nxlendAccData
}

export async function checkHealth(

){}

export async function lendSetAccountRiskPreference(
  nxlend_acc:any,
  risk_level:any
){
  const { publicKey } = useWallet()
  const wallet = useAnchorWallet();
  anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value!, {}));
  const program = new anchor.Program(idl as anchor.Idl, programLendingId);
  const nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key)
  let riskParams
  switch (risk_level) {
    case 0:
      riskParams = {
        low:{}
      }
      break;
    case 1:
      riskParams = {
        middle:{}
      }
      break;
    case 2:
      riskParams = {
        high:{}
      }
      break;
    default:
      break;
  }
  const ix = await program.methods.setAccountRiskPreference(
    riskParams
  ).accounts({
    nxlendMarket: marketAcc,
    nxlendAccount: nxlend_acc_pbk,
    owner: publicKey.value!,
  })
  .signers([])
  .instruction()
  const Ixs = [ix];
  let risk
  switch (risk_level) {
    case 0:
      risk = 'Low'
      break;
    case 1:
      risk = 'Medium'
      break;
    case 2:
      risk = 'High'
      break;
    default:
      break;
  }
  const data = {
    type: 'Set Risk',
    text: `Set risk to ${risk}`,
    typed: 'Set Risk',
    textd: `Set risk to ${risk}`,
  }
  const signature = await nxSendTransaction(Ixs,data,nxlend_acc);
  return signature
}

export async function lendingAccountDeposit(
  amount:any,
  nxlend_acc:any,
  reserve_str:any,
  reserve_mint_str:any,
  decimals: any,
  reserveData: any
){
  const reserve = new web3.PublicKey(reserve_str)
  const reserve_mint = new web3.PublicKey(reserve_mint_str)
  const [liquidity_vault, liquidity_vault_bump] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_seed")), reserve.toBuffer()],
    programLendingId
  );
  const reserve_l_v_g = liquidity_vault;
  const { publicKey } = useWallet()
  const wallet = useAnchorWallet();
  anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value!, {}));
  const program = new anchor.Program(idl as anchor.Idl, programLendingId);
  const user_token_data = await makeTokenAccountIx(
    connection,
    publicKey.value!,
    reserve_mint,
    publicKey.value!,
    true
  )
  const nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key)
  const amountInt = decimalToInteger(amount, decimals)
  const deposit_amount = new anchor.BN(amountInt);
  const ix = await program.methods.depositIntoReserve(
    deposit_amount
  ).accounts({
    nxlendMarket: marketAcc,
    nxlendAccount: nxlend_acc_pbk,
    signer: publicKey.value!,
    reserve: reserve,
    // signerTokenAccount: user_token_acc_key.address,
    signerTokenAccount: user_token_data.AssociatedTokenAddress,
    reserveAssetTokenAccount: reserve_l_v_g,
    tokenProgram: token.TOKEN_PROGRAM_ID
  })
  .signers([])
  .instruction()
  const Ixs = reserve_mint_str === NATIVE_MINT.toBase58() ? wrapInstructionForWSol(publicKey.value!,ix,amountInt) : [ix];
  const data = {
    type: 'Depositing',
    text: `Depositing ${amount} ${reserveData.token_name} into the market`,
    typed: 'Depositing',
    textd: `Depositing ${amount} ${reserveData.token_name} into the market`,
  }
  let priceFeeds:any = []
  if(reserveData.priceFeedID) {
    priceFeeds.push(reserveData.priceFeedID)
  }
  const signature = await nxSendTransaction(Ixs,data,nxlend_acc,priceFeeds);
  return signature
}
function getBorrowRemindingAccountList(
  nxlend_acc_data:any,
  reserve_str: any
){
  const reserve_list = JSON.parse(JSON.stringify(appStore.reserve.state.reserveList))
  const nxlendAccData = nxlend_acc_data
  const mybalance = nxlendAccData.userMarketInfo.userReserves
  let activeBalanceList:any = []
  let newBorrow = true
  mybalance.forEach(e => {
    if(e.active) {
      if(e.reservePk.toBase58() === reserve_str )
      newBorrow = false
    }
  });
  let newBorrowAdded = false
  mybalance.forEach(e => {
    if(e.active) {
      activeBalanceList.push(e.reservePk)
    } else {
      if(!newBorrowAdded && newBorrow) {
        activeBalanceList.push(new web3.PublicKey(reserve_str))
        newBorrowAdded = true
      }
    }
  });
  let arr:any = []
  let priceFeeds:any = []
  activeBalanceList.forEach(r => {
    reserve_list.forEach(e => {
      if(e.reserve_key === r.toBase58()) {
        const reserve = new web3.PublicKey(e.reserve_key)
        arr.push({
          isSigner: false,
          isWritable: true,
          pubkey: reserve
        })
        const pythOracleReserve = new web3.PublicKey(e.pythOracle)
        arr.push({
          isSigner: false,
          isWritable: true,
          pubkey: pythOracleReserve
        })
        const priceFeed = e.priceFeedID
        if(priceFeed) {
          priceFeeds.push(priceFeed)
        }
      }
    });
  });
  return {borrowRemindingAccountList:arr,priceFeeds:priceFeeds}
}
export async function lendingAccountBorrow(
  amount:any,
  nxlend_acc:any,
  reserve_str:any,
  reserve_mint_str:any,
  decimals: any,
  reserveData: any
){
  const reserve = new web3.PublicKey(reserve_str)
  const reserve_mint = new web3.PublicKey(reserve_mint_str)
  const [liquidity_vault, liquidity_vault_bump] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_seed")), reserve.toBuffer()],
    programLendingId
  );
  const reserve_l_v_g = liquidity_vault;
  const [liquidity_vault_authority, liquidity_vault_authority_bump] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_auth_seed")), reserve.toBuffer()],
    programLendingId
  );
  const reserve_l_v_a_g = liquidity_vault_authority;
  const { publicKey } = useWallet()
  const wallet = useAnchorWallet();
  anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value!, {}));
  const program = new anchor.Program(idl as anchor.Idl, programLendingId);

  const user_token_data = await makeTokenAccountIx(
    connection,
    publicKey.value!,
    reserve_mint,
    publicKey.value!,
    true
  )
  const nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key)
  const amountInt = decimalToInteger(amount, decimals)
  const borrow_amount = new anchor.BN(amountInt);
  const {borrowRemindingAccountList,priceFeeds} = getBorrowRemindingAccountList(nxlend_acc.data,reserve_str)
  const ix = await program.methods.borrowFromReserve(
    borrow_amount
  ).accounts({
    nxlendMarket: marketAcc,
    nxlendAccount: nxlend_acc_pbk,
    signer: publicKey.value!,
    reserve: reserve,
    destinationTokenAccount: user_token_data.AssociatedTokenAddress,
    tokenAccountAuthority:reserve_l_v_a_g,
    reserveTokenAccount:reserve_l_v_g,
    tokenProgram: token.TOKEN_PROGRAM_ID
  })
  .remainingAccounts(borrowRemindingAccountList)
  .signers([]).instruction()
  let Ixs = reserve_mint_str === NATIVE_MINT.toBase58() ? wrapInstructionForWSol(publicKey.value!,ix,0) : [ix];

  Ixs = [user_token_data.ix].concat(Ixs)

  const data = {
    type: 'Borrowing',
    text: `Borrowing ${amount} ${reserveData.token_name} from the market`,
    typed: 'Borrowing',
    textd: `Borrowing ${amount} ${reserveData.token_name} from the market`,
  }
  const signature = await nxSendTransaction(Ixs,data,nxlend_acc,priceFeeds);
  
  return signature
}

export async function lendingAccountRepay(
  amount:any,
  nxlend_acc:any,
  reserve_str:any,
  reserve_mint_str:any,
  decimals: any,
  all: boolean,
  reserveData: any
){
  const reserve = new web3.PublicKey(reserve_str)
  const reserve_mint = new web3.PublicKey(reserve_mint_str)
  const [liquidity_vault, liquidity_vault_bump] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_seed")), reserve.toBuffer()],
    programLendingId
  );
  const reserve_l_v_g = liquidity_vault;
  const [liquidity_vault_authority, liquidity_vault_authority_bump] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_auth_seed")), reserve.toBuffer()],
    programLendingId
  );
  const reserve_l_v_a_g = liquidity_vault_authority;
  const { publicKey } = useWallet()
  const wallet = useAnchorWallet();
  anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value!, {}));
  const program = new anchor.Program(idl as anchor.Idl, programLendingId);

  const user_token_data = await makeTokenAccountIx(
    connection,
    publicKey.value!,
    reserve_mint,
    publicKey.value!,
    true
  )
  const nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key)
  const amountInt = decimalToInteger(amount, decimals)
  const repay_amount = new anchor.BN(amountInt);
  const ix = await program.methods.repayToReserve(
    repay_amount,all
     //repay_amount,false
  ).accounts({
    nxlendMarket: marketAcc,
    nxlendAccount: nxlend_acc_pbk,
    signer: publicKey.value!,
    reserve: reserve,
    signerTokenAccount: user_token_data.AssociatedTokenAddress,
    reserveTokenAccount:reserve_l_v_g,
    tokenProgram: token.TOKEN_PROGRAM_ID
  })
  .signers([])
  .instruction()
  const Ixs = reserve_mint_str === NATIVE_MINT.toBase58() ? wrapInstructionForWSol(publicKey.value!,ix,amountInt) : [ix];
  
  const data = {
    type: 'Repaying',
    text: `Repaying ${amount} ${reserveData.token_name} into the market`,
    typed: 'Repaying',
    textd: `Repaying ${amount} ${reserveData.token_name} into the market`,
  }
  let priceFeeds:any = []
  if(reserveData.priceFeedID) {
    priceFeeds.push(reserveData.priceFeedID)
  }
  const signature = await nxSendTransaction(Ixs,data,nxlend_acc,priceFeeds);
  
  return signature
}

function i80f48FromNumber(x: number): anchor.BN {
  let MULTIPLIER_NUMBER = Math.pow(2, 48);
  let int_part = Math.trunc(x);
  let v = new anchor.BN(int_part).iushln(48);
  v.iadd(new anchor.BN((x - int_part) * MULTIPLIER_NUMBER));
  return v;
}
export async function setRepayLTV(
  nxlend_acc:any,
  amount: any
  ){
  //lending reserve_mint_a by user2
  const { publicKey } = useWallet()
  const nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key)
  const wallet = useAnchorWallet();
  anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value!, {}));
  const program = new anchor.Program(idl as anchor.Idl, programLendingId);
  const numberAmount = Number(amount)
  const i80f48Amount = i80f48FromNumber(numberAmount)
  const ix = await program.methods.setAccountRepayLtv(
    //i80f48Amount
    {value: i80f48Amount}
  ).accounts({
    nxlendMarket: marketAcc,
    nxlendAccount: nxlend_acc_pbk,
    owner: publicKey.value!
  })
  .signers([])
  .instruction()
  const Ixs = [ix];
  const data = {
    type: 'Set Repay LTV',
    text: `Set Repay LTV at ${amount*100} %`,
    typed: 'Set Repay LTV',
    textd: `Set Repay LTV at ${amount*100} %`,
  }
  const signature = await nxSendTransaction(Ixs,data,nxlend_acc);
  
  return signature
}
function getWithdrawRemindingAccountList(
  nxlend_acc_data:any,
  reserve_str: any,
  all: boolean
){
  const reserve_list = JSON.parse(JSON.stringify(appStore.reserve.state.reserveList))
  const nxlendAccData = nxlend_acc_data
  const mybalance = nxlendAccData.userMarketInfo.userReserves
  let activeBalanceList:any = []
  mybalance.forEach(e => {
    if(e.active) {
      activeBalanceList.push(e)
    }
  });
  let arr:any = []
  let priceFeeds:any = []
  activeBalanceList.forEach(r => {
    if(r.reservePk.toBase58() !== reserve_str || !all ) {
      reserve_list.forEach(e => {
        if(e.reserve_key === r.reservePk.toBase58()) {
          const reserve = new web3.PublicKey(e.reserve_key)
          arr.push({
            isSigner: false,
            isWritable: true,
            pubkey: reserve
          })
          const pythOracleReserve = new web3.PublicKey(e.pythOracle)
          arr.push({
            isSigner: false,
            isWritable: true,
            pubkey: pythOracleReserve
          })
          const priceFeed = e.priceFeedID
          if(priceFeed) {
            priceFeeds.push(priceFeed)
          }
        }
      });
    }
  });
  return {withdrawRemindingAccountList:arr,priceFeeds:priceFeeds}
}
export async function lendingAccountWithdraw(
  amount:any,
  nxlend_acc:any,
  reserve_str:any,
  reserve_mint_str:any,
  all: boolean,
  decimals: any,
  reserveData: any
){
  const reserve = new web3.PublicKey(reserve_str)
  const reserve_mint = new web3.PublicKey(reserve_mint_str)
  const [liquidity_vault, liquidity_vault_bump] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_seed")), reserve.toBuffer()],
    programLendingId
  );
  const reserve_l_v_g = liquidity_vault;
  const [liquidity_vault_authority, liquidity_vault_authority_bump] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from(anchor.utils.bytes.utf8.encode("reserve_token_acc_auth_seed")), reserve.toBuffer()],
    programLendingId
  );
  const reserve_l_v_a_g = liquidity_vault_authority;
  const { publicKey } = useWallet()
  const wallet = useAnchorWallet();
  anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value!, {}));
  const program = new anchor.Program(idl as anchor.Idl, programLendingId);

  const user_token_data = await makeTokenAccountIx(
    connection,
    publicKey.value!,
    reserve_mint,
    publicKey.value!,
    true
  )
  const nxlend_acc_pbk = new web3.PublicKey(nxlend_acc.key)
  const amountInt = decimalToInteger(amount, decimals)
  const withdraw_amount = new anchor.BN(amountInt);
  const {withdrawRemindingAccountList,priceFeeds} = await getWithdrawRemindingAccountList(nxlend_acc.data,reserve_str,all)
  //const remindingAccountList = await getWithdrawRemindingAccountList(nxlend_acc.data,reserve_str,false)
  console.log('withdraw remindingAccountList',withdrawRemindingAccountList)
  const ix = await program.methods.withdrawFromReserve(
    withdraw_amount, all
    //withdraw_amount, false
  ).accounts({
    nxlendMarket: marketAcc,
    nxlendAccount: nxlend_acc_pbk,
    signer: publicKey.value!,
    reserve: reserve,
    destinationTokenAccount: user_token_data.AssociatedTokenAddress,
    tokenAccountAuthority:reserve_l_v_a_g,
    reserveTokenAccount:reserve_l_v_g,
    tokenProgram: token.TOKEN_PROGRAM_ID
  })
  .remainingAccounts(withdrawRemindingAccountList)
  .signers([])
  .instruction()
  let Ixs = reserve_mint_str === NATIVE_MINT.toBase58() ? wrapInstructionForWSol(publicKey.value!,ix,0) : [ix];
  Ixs = [user_token_data.ix].concat(Ixs)
  const data = {
    type: 'Withdrawing',
    text: `Withdrawing ${amount} ${reserveData.token_name} from the market`,
    typed: 'Withdrawing',
    textd: `Withdrawing ${amount} ${reserveData.token_name} from the market`,
  }
  const signature = await nxSendTransaction(Ixs,data,nxlend_acc,priceFeeds);
  
  return signature
}


export async function getBalance(
  token_account: web3.PublicKey
)  {
      console.log('token_account',token_account)
      let tokenAccountBalance2 = await connection.getTokenAccountBalance(token_account);
      return tokenAccountBalance2.value.uiAmount
}

async function makeTokenAccountIx(
  connection: web3.Connection,
  payer: web3.PublicKey,
  mint: web3.PublicKey,
  owner: web3.PublicKey,
  allowOwnerOffCurve: boolean,
  needNew: boolean = true,
){
  const AssociatedTokenAddress = await token.getAssociatedTokenAddress(
    mint,
    owner
  )
  const ix = token.createAssociatedTokenAccountIdempotentInstruction(
    payer, // payer
    AssociatedTokenAddress, // ata
    owner, // owner
    mint // mint
  )
  try {
    const tokenAccount = await token.getAccount(
      connection,
      AssociatedTokenAddress
    )
    return {
      AssociatedTokenAddress,
      ix,
      isExit: true
    }
  } catch (error) {
    return {
      AssociatedTokenAddress,
      ix,
      isExit: false
    }
  }
}
  async function createTokenAccount(
    connection: web3.Connection,
    payer: web3.PublicKey,
    mint: web3.PublicKey,
    owner: web3.PublicKey,
    allowOwnerOffCurve: boolean,
    needNew: boolean = true,
  ){
    const AssociatedTokenAddress = await token.getAssociatedTokenAddress(
      mint,
      owner,
      allowOwnerOffCurve
    )
    try {
      const tokenAccount = await token.getAccount(
        connection,
        AssociatedTokenAddress
      )
      return tokenAccount 
    } catch (error) {
      console.log(error)
      if(!needNew) return null
      const transaction = new web3.Transaction()
      const { sendTransaction } = useWallet()
      transaction.add(
        token.createAssociatedTokenAccountInstruction(
          payer, // payer
          AssociatedTokenAddress, // ata
          owner, // owner
          mint // mint
        )
      );
      const transactionSignature = await sendTransaction(
        transaction,
        connection
      )
      return await createTokenAccount(
        connection,
        payer,
        mint,
        owner,
        allowOwnerOffCurve
      )
    }
  }

  function getTotalAssetQuantity(totalAssetShares,assetShareValue): BigNumber {
    return totalAssetShares.times(assetShareValue);
  }

  function getTotalLiabilityQuantity(totalLiabilityShares,liabilityShareValue): BigNumber  {
    return totalLiabilityShares.times(liabilityShareValue);
  }

  function getAssetQuantity(assetShares: BigNumber,assetShareValue: any): BigNumber {
    return assetShares.times(assetShareValue);
  }

  function getLiabilityQuantity(liabilityShares: BigNumber,liabilityShareValue:any): BigNumber {
    return liabilityShares.times(liabilityShareValue);
  }

  function getAssetShares(assetQuantity: BigNumber,assetShareValue:any): BigNumber {
    return assetQuantity.times(assetShareValue);
  }

  function getLiabilityShares(liabilityQuantity: BigNumber,liabilityShareValue:any): BigNumber {
    return liabilityQuantity.times(liabilityShareValue);
  }

  function makeWrapSolIxs(walletAddress: PublicKey, amount: BigNumber){
    const address = getAssociatedTokenAddressSync(NATIVE_MINT, walletAddress, true);
    const ixs = [createAssociatedTokenAccountIdempotentInstruction(walletAddress, address, walletAddress, NATIVE_MINT)];
  
    if (amount.gt(0)) {
      // const nativeAmount = uiToNative(amount, 9).toNumber() + 10000;
      // const nativeAmount = amount.plus(10000).toNumber();
      const nativeAmount = Number(amount.times(new BigNumber(1).plus(SOL_WRAP_FACTOR)).toNumber().toFixed(0));
      console.log(amount)
      console.log(nativeAmount)
      ixs.push(
        web3.SystemProgram.transfer({ fromPubkey: walletAddress, toPubkey: address, lamports: nativeAmount }),
        createSyncNativeInstruction(address)
      );
    }
  
    return ixs;
  }
  
  function makeUnwrapSolIx(walletAddress: PublicKey) {
    const address = getAssociatedTokenAddressSync(NATIVE_MINT, walletAddress, true);
    return createCloseAccountInstruction(address, walletAddress, walletAddress);
  }
  function wrapInstructionForWSol(
    walletAddress: PublicKey,
    ix: any,
    amount: any)
  {
    return [...makeWrapSolIxs(walletAddress, new BigNumber(amount)), ix,makeUnwrapSolIx(walletAddress)];
  }
  export async function confirmTransactionOld(tx:any) {
    let i = 0
    let flag = false
    while(i<100) {
      console.log('check times',i)
      const result = await connection.getSignatureStatus(tx, {
        searchTransactionHistory: true,
      });
      if(!result) {
        await delay(500)
        i = i+1
      } else {
        console.log('check res',result.value?.confirmationStatus)
        console.log('check res',result)
        if(!result.value) {
          await delay(500)
          i = i+1
        } else {
          i = i+1
          if(result.value?.confirmationStatus === 'processed') {
            await delay(500)
          }
          if(result.value?.confirmationStatus === 'confirmed') {
            flag = true
            i = 100
          }
        }
      }
    }
    return flag;
  }
  export async function confirmTransaction(tx:any) {
    const res = await connection.confirmTransaction(tx, "confirmed");
    return res;
  }
  async function getPriceUpdateData($priceFeeds:any) {
    const priceServiceConnection = new PriceServiceConnection(
      "https://hermes.pyth.network/",
      { priceFeedRequestConfig: { binary: true } }
    );
    let priceFeeds:any = []
    if($priceFeeds.length < 1) {
      priceFeeds.push('0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a')
    } else {
      priceFeeds = $priceFeeds
    }
    return await priceServiceConnection.getLatestVaas(priceFeeds);
  }
  async function nxSendTransaction($ixs:any,$data:any,nxlend_acc:any,$priceFeeds?:any) {
    let Ixs = nxlend_acc.isExist ? $ixs : [...[nxlend_acc.createAccountIx],...$ixs]
    const { publicKey,signTransaction,connected } = useWallet()
    // fee set
    const feeSetJson = window.localStorage.getItem('feeSet')
    const feeSet = JSON.parse(feeSetJson!)
    const feeAmount = feeSet ? feeSet.feeAmount : 0
    const feeInt = decimalToInteger(feeAmount,9)
    if(feeAmount > 0) {
      const computePriceIx = web3.ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: Number(feeInt),
      });
      Ixs = [computePriceIx].concat(Ixs)
    }
    // update limit
    const setComputeUnitLimitInstruction = web3.ComputeBudgetProgram.setComputeUnitLimit(
      { units: 400_000 }
    );
    Ixs = [setComputeUnitLimitInstruction].concat(Ixs)
    console.log('$ixs',$ixs)
    const getLatestBlockhashAndContext = await connection.getLatestBlockhashAndContext();
    const blockhash = getLatestBlockhashAndContext.value.blockhash;
    const lookupTableAccount = (await connection.getAddressLookupTable(lookUpTable)).value;
    const messageV0 = new web3.TransactionMessage({
      payerKey: publicKey.value!,
      recentBlockhash: blockhash,
      instructions: Ixs, // Note: this is an array of instructions
    }).compileToV0Message([lookupTableAccount!]);
    const transactionV0 = new web3.VersionedTransaction(messageV0);
    let tx
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get('fee');
    
    console.log(paramValue); 
    const updateSelf = paramValue==='true' ? true : false
    let finalTransactions
    if(updateSelf) {
      // Create a v0 transaction from the v0 message
      // for pyth2.0
      const wallet = useAnchorWallet();
      const pythSolanaReceiver = new PythSolanaReceiver({
        connection,
        wallet: wallet.value! as Wallet,
      });
      const priceUpdateData = await getPriceUpdateData($priceFeeds);
      console.log('priceUpdateData',priceUpdateData)
      const {
        postInstructions,
        closeInstructions,
        priceFeedIdToPriceUpdateAccount,
      } = await pythSolanaReceiver.buildUpdatePriceFeedInstructions(
        priceUpdateData,0
      );
      console.log('postInstructions',postInstructions)
      console.log('Ixs',Ixs)
      const ConsumerInstructions = $ixs.map((ix:any)=>{
              const priceix =
              {
                instruction: ix,
                signers:[],
              }
              return priceix
            })
      const transactions = await pythSolanaReceiver.batchIntoVersionedTransactions(
        [...postInstructions,...ConsumerInstructions,...closeInstructions],
        { computeUnitPriceMicroLamports: 50000 },
      );
      // tx = await pythSolanaReceiver.provider.sendAll([...transactions], {
      //   skipPreflight: false,
      //   preflightCommitment: "processed",
      // });
      try {
        tx = await pythSolanaReceiver.provider.sendAll([...transactions], {
          skipPreflight: false,
          preflightCommitment: "processed",
        });
        const data = {
          type: $data.type,
          text: $data.text,
          typed: $data.typed,
          textd: $data.textd,
          tx:updateSelf ? tx[tx.length -1]:tx
        }
        appStore.pendingtx.state.txList.push(data)
        const txRes = await connection.confirmTransaction(updateSelf ? tx[tx.length -1]:tx, "confirmed");
        //await connection.confirmTransaction(tx, "confirmed");
        //await confirmTransaction(tx);
        if(txRes && !txRes.value.err) {
          firework1()
        }
        return tx 
      } catch (error:any) {
        console.log('error',error)
        if(error.toString().indexOf('User rejected') > -1) {
          ElMessage({
            message: 'User rejected',
            type: 'warning',
          })
        }
        return false
      }
    }else {
      try {
        
        const { wallet,sendTransaction,signAllTransactions} = useWallet()
        if(wallet.value?.adapter.name === 'Solflare') {
          await wallet.value?.adapter.connect()
          await wallet.value?.adapter.autoConnect()
        }
        tx = await sendTransaction(transactionV0, connection)
        const data = {
          type: $data.type,
          text: $data.text,
          typed: $data.typed,
          textd: $data.textd,
          tx:updateSelf ? tx[tx.length -1]:tx
        }
        appStore.pendingtx.state.txList.push(data)
        const txRes = await connection.confirmTransaction(updateSelf ? tx[tx.length -1]:tx, "confirmed");
        //await connection.confirmTransaction(tx, "confirmed");
        //await confirmTransaction(tx);
        
        if(txRes && !txRes.value.err) {
          firework1()
        }
        return tx 
      } catch (error:any) {
        console.log('error',error)
        if(error.toString().indexOf('User rejected') > -1) {
          ElMessage({
            message: 'User rejected',
            type: 'warning',
          })
        }
        return false
      }
    }
  }

  export async function updateReserve(
    reserveKey:  web3.PublicKey,
    setFlag: boolean
  ): Promise<web3.PublicKey> {
    //update reserve
      const { publicKey } = useWallet()
      const wallet = useAnchorWallet();
      anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value!, {}));
      const program = new anchor.Program(idl as anchor.Idl, programLendingId);
      let assetValueRatio
      let assetValueLiqRatio
      let debtValueRatioHighRisk
      let debtValueRatioMidRisk
      let debtValueRatioLowRisk
      let debtValueLiqRatio
      if(setFlag) {
        assetValueRatio = 0.5
        assetValueLiqRatio = 0.65
        // assetValueRatio = 0
        // assetValueLiqRatio = 0
        debtValueRatioHighRisk = 15.1
        debtValueRatioMidRisk = 15.5
        debtValueRatioLowRisk = 16
        debtValueLiqRatio = 15
      } else {
        assetValueRatio = 0.5
        assetValueLiqRatio = 0.65
        // assetValueRatio = 0
        // assetValueLiqRatio = 0
        debtValueRatioHighRisk = 2
        debtValueRatioMidRisk = 2.5
        debtValueRatioLowRisk = 3
        debtValueLiqRatio = 1.5
      }
      console.log('start change reserve')
      const tx = await program.methods.updateReserve(
        {
          assetValueRatio: {
            value: i80f48FromNumber(assetValueRatio)
          },
          assetValueLiqRatio:{
            value: i80f48FromNumber(assetValueLiqRatio)
          },
          debtValueRatioHighRisk:{
            value: i80f48FromNumber(debtValueRatioHighRisk)
          },
          debtValueRatioMidRisk:{
            value: i80f48FromNumber(debtValueRatioMidRisk)
          },
          debtValueRatioLowRisk:{
            value: i80f48FromNumber(debtValueRatioLowRisk)
          },
  
          debtValueLiqRatio:{
            value: i80f48FromNumber(debtValueLiqRatio)
          },
          capacity: null,
          maxBorrowable: null,
          operationalState: null,
          oracle: null,
          interestSetting: null,
          reserveType: null,
          maxExposure: null,
          maxPriceAge: null
        }
      ).accounts({
        nxlendMarket: marketAcc,
        manager: publicKey.value!,
        reserve: reserveKey
      })
      .signers([])
      .rpc();
      return reserveKey;
  
    }

    const aprToApy = (apr: number, compoundingFrequency = HOURS_PER_YEAR) =>
    (1 + apr / compoundingFrequency) ** compoundingFrequency - 1;

    export async function getFee() {
      try {
        const res = await connection.getRecentPrioritizationFees()
        let fee = 0
        res.forEach(e => {
          if(e.prioritizationFee > fee) {
            fee = e.prioritizationFee
          }
        });
        const feeNumber = integerToDecimal(fee,9)
        return feeNumber
      } catch (error) {
        const feeNumber = integerToDecimal(5000,9)
        return feeNumber
      }
    }
function firework1(){
  const end = Date.now() + 2 * 1000;

  // go Buckeyes!
  const colors = ["#7340c9", "#ffffff"];
  
  (function frame() {
    confetti({
      shapes: ["star"],
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: colors,
    });
  
    confetti({
      shapes: ["star"],
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: colors,
    });
  
    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  })();
}

export async function getAllNxlendAccounts() {
  return (
    await connection.getProgramAccounts(programLendingId, {
      dataSlice: {
        offset: 0,
        length: 0,
      },
      filters: [
        {
          memcmp: {
            offset: 0,
            bytes: bs58.encode(BorshAccountsCoder.accountDiscriminator('nxlendAccount')),
          },
        },
        {
          memcmp: {
            bytes: marketAcc.toBase58(),
            offset: 8 + 32, 
          },
        },
      ],
    })
  ).map((a) => 
    a.pubkey
  );
}

export async function loadAllNxlendAccounts() {
  console.log("Loading data, this may take a moment...");
  let allKeys:any = [];
  const reservesData = await getAllReserveData();
  allKeys = await getAllNxlendAccounts();

  console.log("Retrieved all Nxlend account addresses, found: %d", allKeys.length);
  const [slot, ais] = await chunkedGetRawMultipleAccountInfos(
    connection,
    allKeys.map((k) => k.toBase58()),
    16 * 64,
    64
  );
  const accountKeys = allKeys;

  const totalAccounts = ais.size;
  let processedAccounts = 0;
  const accountDataList =  Array.from(ais)
  for (const [key, accountInfo] of accountDataList) {
    const pubkey = new PublicKey(key);
    const parsedData = accountDataDecode(accountInfo.data);
    const mybalanceData = parsedData.userReserves
    const displayData = getAccountInfoDisplay(reservesData,parsedData,mybalanceData)
    const account = {address:pubkey, data: [...displayData]};
    appStore.allaccounts.state.allaccounts.set(key, account);

    processedAccounts++;
    if (processedAccounts % 5000 === 0) {
      const progress = ((processedAccounts / totalAccounts) * 100).toFixed(2);
      console.log("Processed %d accounts out of %d (%s%%)", processedAccounts, totalAccounts, progress);
    }
  }

  console.log("Finished loading all Nxlend accounts");
}
const coder = new BorshCoder(idl as anchor.Idl);
export function accountDataDecode(encoded:Buffer) {
  const data = coder.accounts.decode('NxlendAccount', encoded);
  const market = data.market
  const repayLtv = data.repayLtv
  const riskPreference = data.riskPreference
  const accountFlags = data.accountFlags
  const userReserves = data.userMarketInfo.userReserves
  return {market,repayLtv,riskPreference,accountFlags,userReserves}
}
export function getAccountInfoDisplay(reservesData:any,accData:any,mybalanceData:any){
  let reserveList = JSON.parse(JSON.stringify(reservesData))
  let nxlendAccData = JSON.parse(JSON.stringify(accData))
  let mybalance = [...mybalanceData]
  const self_risk = JSON.stringify(nxlendAccData.riskPreference)
  let risk_level = 1
  if(self_risk.indexOf('low') > -1){
    risk_level = 0
  }
  if(self_risk.indexOf('middle') > -1){
    risk_level = 1
  }
  if(self_risk.indexOf('high') > -1){
    risk_level = 2
  }
  let balanceActive:any = []
  mybalance.forEach(e => {
    if(e.active) {
      balanceActive.push(e)
    }
  });
  balanceActive.forEach(e => {
    reserveList.forEach(r => {
      if(e.active && e.reservePk.toBase58() === r.reserve_key ) {
        r.my_supply = integerToDecimal(getTotalAssetQuantity(wrappedI80F48toBigNumber(e.assetNtokenAmount),r.asset_ntoken_ratio).toFixed(0),r.decimals)
        r.my_borrow = integerToDecimal(getTotalLiabilityQuantity(wrappedI80F48toBigNumber(e.debtNtokenAmount),r.debit_ntoken_ratio).toFixed(0),r.decimals)
      }
    });
  });
  reserveList.forEach(r => {
    switch (risk_level) {
      case 0:
        r.debit_set_ratio = r.debit_low_ratio
        break;
      case 1:
        r.debit_set_ratio = r.debit_mid_ratio
        break;
      case 2:
        r.debit_set_ratio = r.debit_high_ratio
        break;
    }
  });
  return reserveList
}

export async function getAllReserveData () {
  // const onLineReserveList = await getReserveList()
  let onLineReserveList
  //const onLineReserveList  = localOnlineReserveList
  if(appStore.reserve.state.onlineReserveList.length > 0) {
    onLineReserveList = JSON.parse(JSON.stringify(appStore.reserve.state.onlineReserveList))
  } else {
    onLineReserveList = await getReserveList()
    appStore.reserve.state.onlineReserveList = onLineReserveList
  }
  let reserveList = onLineReserveList.map((e:any)=>{
    //e.token_img = require(e.logoURI) ? require(e.logoURI) : require('@/assets/token/sol.webp')
    e.token_img = e.logoURI
    e.token_name = e.tokenSymbol
    e.reserve_mint_key = e.tokenMint
    e.reserve_key = e.reserveAddress
    e.priceFeedID = e.priceFeedID ? e.priceFeedID : null
    e.reserveAta = '',
    e.total_supply = 0,
    e.total_borrow = 0,
    e.supply_apr = 0,
    e.borrow_apr = 0,
    e.supply_apy = 0,
    e.borrow_apy = 0,
    e.ltv = 0,
    e.asset_ntoken_ratio = 0,
    e.debit_ntoken_ratio = 0,
    e.my_supply = 0,
    e.my_borrow = 0,
    e.amount = 0,
    e.capacity = 0,
    e.maxBorrow = 0,
    e.usd_price = 0,
    e.usd_price_low = 0,
    e.usd_price_high = 0,
    e.usd_price_weighted= 0,
    e.usd_price_weighted_low= 0,
    e.usd_price_weighted_high= 0,
    e.decimals=0,
    e.asset_ratio= 0,
    e.asset_liq_ratio= 0,
    e.debit_set_ratio= 0,
    e.debit_high_ratio= 0,
    e.debit_mid_ratio= 0,
    e.debit_low_ratio= 0,
    e.debit_liq_ratio= 0,
    e.type=1,
    e.interestSetting={}
    return e
  })
  const { publicKey } = useWallet()
  // let reserveList = JSON.parse(JSON.stringify(reserve_list))
  let autoRepay
  const wallet = useAnchorWallet();
  anchor.setProvider(new anchor.AnchorProvider(connection, wallet.value!, {}));
  const program = new anchor.Program(idl as anchor.Idl, programLendingId);
  const reserves = reserveList.map((e)=>{
    console.log('e.reserve_key',e.reserve_key)
    return new web3.PublicKey(e.reserve_key)
    //return new web3.PublicKey()
  })
  let nxlendReserveDatas = await getOnchainReserveData(program,reserves)
  console.log('nxlendReserveDatas',nxlendReserveDatas)
  let i = 0
  while (i < reserveList.length) {
    const tokenMint = new web3.PublicKey(reserveList[i].reserve_mint_key)
    let nxlendReserveData:any
    nxlendReserveDatas.forEach((e:any) => {
      if(e.tokenMint.equals(tokenMint)) {
        nxlendReserveData = e
      }
    });
    if(!nxlendReserveData) {
      i = i + 1
      continue
    }
    reserveList[i].type = 0
    if(JSON.stringify(nxlendReserveData.setting.reserveType).indexOf('collateral') > -1) {
      reserveList[i].type = 1
    }
    if(JSON.stringify(nxlendReserveData.setting.reserveType).indexOf('noCollaAddup') > -1) {
      reserveList[i].type = 2
    }
    if(reserveList[i].type === 0) {
      ElMessage({
        message: 'error collateral type',
        type: 'warning',
      })
    }
    if(JSON.stringify(nxlendReserveData.setting.oracleType).indexOf('pythEma') > -1) {
      reserveList[i].oracleType = 'pythV1'
    }
    if(JSON.stringify(nxlendReserveData.setting.oracleType).indexOf('pythV2') > -1) {
      reserveList[i].oracleType = 'pythV2'
    }
    reserveList[i].pythOracle = nxlendReserveData.setting.oracleKeys[0].toBase58()
    reserveList[i].capacity = integerToDecimal(nxlendReserveData.setting.capacity,nxlendReserveData.tokenDecimals)
    reserveList[i].maxBorrow = integerToDecimal(nxlendReserveData.setting.maxBorrowable,nxlendReserveData.tokenDecimals)
    reserveList[i].reserveAta = nxlendReserveData.tokenInfo.tokenAccount
    reserveList[i].asset_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.assetValueRatio).toNumber()
    reserveList[i].asset_liq_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.assetValueLiqRatio).toNumber()
    reserveList[i].debit_low_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueRatioLowRisk).toNumber()
    reserveList[i].debit_mid_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueRatioMidRisk).toNumber()
    reserveList[i].debit_high_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueRatioHighRisk).toNumber()
    reserveList[i].debit_liq_ratio = wrappedI80F48toBigNumber(nxlendReserveData.setting.debtValueLiqRatio).toNumber()
    const totalAssetQuantity = getTotalAssetQuantity(wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.reserveAssetNtokenAmount),wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.assetNtokenRatio))
    const totalLiabilityQuantity = getTotalLiabilityQuantity(wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.reserveDebtNtokenAmount),wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.debtNtokenRatio))
    reserveList[i].decimals = nxlendReserveData.tokenDecimals
    // const ltv = new BigNumber(1).div(wrappedI80F48toBigNumber(debitValueRatioRisk)).times(wrappedI80F48toBigNumber(nxlendReserveData.setting.assetValueRatio)).times(100).toFixed(2)
    // reserveList[i].ltv = ltv
    const interestSetting = {
      rateChangeUr1: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.rateChangeUr1).toString(),
      rateChangeUr2: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.rateChangeUr2).toString(),
      irUr1: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.irUr1).toString(),
      irUr2: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.irUr2).toString(),
      maxIr: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.maxIr).toString(),
      treasuryBaseApr: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.treasuryBaseApr).toString(),
      treasuryAdditionRatio: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.treasuryAdditionRatio).toString(),
      marketFeeBase: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.marketFeeBase).toString(),
      marketAdditionRatio: wrappedI80F48toBigNumber(nxlendReserveData.setting.interestSetting.marketAdditionRatio).toString()
    }
    reserveList[i].interestSetting = interestSetting
    reserveList[i].asset_ntoken_ratio = wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.assetNtokenRatio)
    reserveList[i].debit_ntoken_ratio = wrappedI80F48toBigNumber(nxlendReserveData.creditDebit.debtNtokenRatio)
    reserveList[i].total_supply = integerToDecimal(totalAssetQuantity.toFixed(0),nxlendReserveData.tokenDecimals)
    reserveList[i].total_borrow = integerToDecimal(totalLiabilityQuantity.toFixed(0),nxlendReserveData.tokenDecimals)
    const apy = getApyNew(reserveList[i].total_supply,reserveList[i].total_borrow,reserveList[i].interestSetting)
    reserveList[i].supply_apr = Number.isNaN(apy.supplyAPR.toNumber()) ? 0: new BigNumber(apy.supplyAPR).multipliedBy(100).toFixed(2)
    reserveList[i].supply_apy = Number.isNaN(apy.supplyAPY) ? 0:new BigNumber(apy.supplyAPY).multipliedBy(100).toFixed(2)
    reserveList[i].borrow_apr = new BigNumber(apy.borrowAPR).multipliedBy(100).toFixed(2)
    reserveList[i].borrow_apy = new BigNumber(apy.borrowAPY).multipliedBy(100).toFixed(2)
    i = i + 1
  }
  const pythOracleList = reserveList.map((e)=>{
    return {type: e.oracleType, pythOracle:new web3.PublicKey(e.pythOracle), reserveAddress:e.reserve_key, priceFeed: e.priceFeedID}
  })
  let price_data = await getOnchainPriceData(pythOracleList)
  // try {
  //   price_data = await connection.getMultipleAccountsInfo(pythOracleList)
  // } catch (error) {
  //   console.log('getMultipleAccountsInfo pythOracleList',error)
  // }
  reserveList.forEach(r => {
    price_data.forEach(p => {
      if(p.reserveAddress === r.reserve_key) {
        const priceRes = p.priceData
        r.usd_price = priceRes.priceRealtime.price
        r.usd_price_low = priceRes.priceRealtime.lowestPrice
        r.usd_price_high = priceRes.priceRealtime.highestPrice
        r.usd_price_weighted = priceRes.priceWeighted.price
        r.usd_price_weighted_low = priceRes.priceWeighted.lowestPrice
        r.usd_price_weighted_high = priceRes.priceWeighted.highestPrice
      }
    });
  });
  reserveList.forEach(e => {
    e.ltv = new BigNumber(1).div(e.debit_set_ratio).times(100).toFixed(2)
  });
  return reserveList
}
export async function signMsg ($msg:any){
  const { signMessage,sendTransaction,publicKey } = useWallet()
  console.log('sendTransaction',sendTransaction)
  console.log('signMessage',signMessage)
  let res
  let randomNumbers
  if (window.crypto && window.crypto.getRandomValues) {
    randomNumbers = new Uint32Array(1);
    window.crypto.getRandomValues(randomNumbers);
    console.log(randomNumbers);
  } else {
    console.log('当前环境不支持生成真随机数');
    return null
  }
  const nonce = randomNumbers[0].toString() + new Date().getTime().toString()
  console.log('user address',publicKey.value?.toBase58())
  console.log('nonce',nonce)
  const msg = $msg + nonce
  console.log('msg',msg)
  const msgBytes = decodeUTF8(msg)
  if(signMessage.value) {
    try {
      res = await signMessage.value(msgBytes)
      console.log('sign',res)
      console.log('sign bs58',bs58.encode(res))
      console.log('sign',uint8ArrayToHex(res))
      console.log('sign',uint8ArrayToBase64(res))
      console.log('sign',res.toLocaleString())
      console.log('sign',res.toString())
    } catch (error) {
      return null
    }
  }else {
    return null
  }
  return bs58.encode(res)
}
function uint8ArrayToBase64(uint8Array:any) {
  let binary = '';
  uint8Array.forEach((byte) => (binary += String.fromCharCode(byte)));
  return btoa(binary);
}
function uint8ArrayToHex(uint8Array:any) {
  return Array.from(uint8Array)
    .map((byte:any) => byte.toString(16).padStart(2, '0'))
    .join('');
}