<template>
  <div class="rwa">
      <div class="text-wrapper text-wrapper-m">
        <div class="rwa-title">RWA</div>
        <div class="rwa-desc">CONNECT ON AND OFF CHAIN</div>
      </div>
      <div class="pics">
        <!-- <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item">
          <img src="@/assets/img/rwa1.jpg"/>
        </div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"><img src="@/assets/img/rwa2.jpg"/></div>
        <div class="item"><img src="@/assets/img/rwa3.jpg"/></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div> -->
        <img src="@/assets/img/rwa.png"/>
      </div>
      <div class="text-wrapper">
        <div class="rwa-title desktop ">RWA</div>
        <div class="rwa-desc desktop">CONNECT ON AND OFF CHAIN</div>
        <div class="texts">
          <div class="text-item">
            <div class="title"><div class="dot"></div>Treasury Bonds</div>
            <div class="text">High security</div>
            <div class="text">Low interest rates</div>
          </div>
          <div class="text-item">
            <div class="title"><div class="dot"></div>Fixed Income Products</div>
            <div class="text">High credit and liquidity</div>
            <div class="text">by worldwide investment banking</div>
          </div>
          <div class="text-item">
            <div class="title"><div class="dot"></div>Asset Package of Emerging Market</div>
            <div class="text">High interest rates</div>
            <div class="text">guaranteed by the insurance institution</div>
          </div>
        </div>
      </div>
      <img class="bg" src="@/assets/img/rwabg1.svg" />
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.rwa {
  min-height: 1039px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  .text-wrapper-m {
    display: none;
  }
  .pics {
    width: 60%;
    display: flex;
    //flex-wrap: wrap;
    justify-content: end;
    // transform: rotate(45deg);
    color: #fff;
    img{
      width:100%;
      max-width:820px;
    }
    // .item {
    //   width: 205px;
    //   height: 205px;
    //   overflow: hidden;
    //   img{
    //     height: 140%;
    //     transform: rotate(-45deg);
    //   }
    // }
    // .item:nth-child(1) {
    //   opacity: 0;
    //   background: #D9D9D9;
    // }
    // .item:nth-child(2) {
    //   opacity: 0.01;
    //   background: #D9D9D9;
    // }
    // .item:nth-child(3) {
    //   opacity: 0.03;
    //   background: #D9D9D9;
    // }
    // .item:nth-child(4) {
    //   opacity: 0;
    //   background: #D9D9D9;
    // }
    // .item:nth-child(5) {
    //   opacity: 0.01;
    //   background: #D9D9D9;
    // }
    // .item:nth-child(6) {
    //   opacity: 0.05;
    //   background: #D9D9D9;
    // }
    // .item:nth-child(7) {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }
    // .item:nth-child(8) {
    //   opacity: 0.05;
    //   background: #D9D9D9;
    // }
    // .item:nth-child(9) {
    //   background: rgba(217, 217, 217, 0.05);
    // }
    // .item:nth-child(10) {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }
    // .item:nth-child(11) {
    //   background: url('@/assets/img/rwa3.jpg');
    //   background-size: cover;
    // }
    // .item:nth-child(12) {
    //   opacity: 0.03;
    //   background: #D9D9D9;
    // }
    // .item:nth-child(13) {
    //   opacity: 0;
    //   background: #D9D9D9;
    // }
    // .item:nth-child(14) {
    //   opacity: 0.05;
    //   background: #D9D9D9;
    // }
    // .item:nth-child(15) {
    //   opacity: 0.03;
    //   background: #D9D9D9;
    // }
    // .item:nth-child(16) {
    //   opacity: 0.01;
    //   background: #D9D9D9;
    // }
  }
  .text-wrapper {
    width: 40%;
    .rwa-title {
      color: #FFF;
      font-family: 'Poppins',sans-serif;
      font-size: 70px;
      font-style: normal;
      font-weight: 700;
      line-height: 80px;
      text-transform: uppercase;
      text-align:left;
    }
    .rwa-desc {
      color: #FFF;
      text-align: center;
      font-family: 'Poppins',sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 52px;
      text-align:left;
      opacity:0.5;
    }
  }
  .texts {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    .text-item {
      text-align: left;
      margin-bottom: 20px;
      .title{
        display: flex;
        align-items: center;
        justify-content: start;
        .dot{
          margin: 5px;
          width: 6px;
          height: 6px;
          background-color:#fff;
          border-radius: 3px;
        }
        color: #FFF;
        font-family: 'Poppins',sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .text {
        padding-left:16px;
        color: #717171;
        font-family: 'Poppins',sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .bg {
    position: absolute;
    right: 0;
    bottom: -50%;
  }
}
@media (max-width: 1000px) and (min-width:800px) {
  .rwa {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 160px;
    .text-wrapper-m {
      display: block !important;
    }
    .pics {
      width: 66vw;
      height: 66vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      color: #fff;
      img{
        width:100%;
      }
    }
    .text-wrapper {
      width: 90%;
      .desktop {
        display: none;
      }
      .rwa-title {
        width: 90%;
        color: #FFF;
        font-family: Inter,sans-serif;
        font-size: 70px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 10px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      .rwa-desc {
        width: 90%;
        color: #FFF;
        text-align: center;
        font-family: 'Poppins',sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        opacity:0.5;
      }
    }
    .texts {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      max-width:440px;
      margin:auto;
      .text-item {
        text-align: left;
        margin-bottom: 10px;
        .title{
          color: #FFF;
          font-family: 'Poppins',sans-serif;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .text {
          color: #717171;
          font-family: 'Poppins',sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .bg {
      position: absolute;
      right: 0;
      bottom: -50%;
    }
  }
}
@media (max-width: 800px) {
  .rwa {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    min-height:800px;
    .text-wrapper-m {
      display: block !important;
    }
    .pics {
      width: 88vw;
      height: 88vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      color: #fff;
    }
    .text-wrapper {
      width: 90%;
      .desktop {
        display: none;
      }
      .rwa-title {
        width: 90%;
        color: #FFF;
        font-family: Inter,sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 10px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      .rwa-desc {
        width: 90%;
        color: #FFF;
        text-align: center;
        font-family: 'Poppins',sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        opacity:0.5;
      }
    }
    .texts {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      max-width:330px;
      margin:auto;
      .text-item {
        text-align: left;
        margin-bottom: 10px;
        .title{
          color: #FFF;
          font-family: 'Poppins',sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .text {
          color: #717171;
          font-family: 'Poppins',sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .bg {
      position: absolute;
      right: 0;
      bottom: -50%;
    }
  }
}
</style>
  